import React from "react";
import { RequestPopup } from "../RequestPopup/RequestPopup";
import { StyledButton } from "./PrimaryButtonStyle";

export const PrimaryButton = ({
  children,
  // icon,
  type,
  title,
  onClick,
  font,
  open,
  closeModal,
  tag,
  tag2,
  style,
  useFit,
  subText,
}) => {
  return (
    <div className={`flex w-full lg:w-fit flex-col items-center`} style={style}>
      <RequestPopup
        type={type}
        title={title ? title : "Запрос доступа на 3 дня"}
        closeModal={closeModal}
        open={open}
        tag={tag}
        tag2={tag2}
      />

      <StyledButton font={font} onClick={onClick} style={style} useFit={useFit}>
        {/*{icon && <img src={icon} alt="no img"></img>}*/}
        <span>{children}</span>
      </StyledButton>
      <div className="mt-2">{subText}</div>
    </div>
  );
};
