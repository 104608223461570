import styled from "styled-components";

export const StyledButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: linear-gradient(87.6deg, #313D53 0.11%, #1F4894 100%);
  box-shadow: 0 4px 20px rgba(4, 25, 98, 0.12);
  // box-shadow: 0px 8px 60px rgba(235, 55, 0, 0.3);
  // border: 2px solid white !important;
  border: none;
  border-radius: 100px;
  color: white !important;
  padding: 16px 32px;
  font-family: ${(props) =>
    props.font ? props.font : ` "Sofia Pro", sans-serif;`};
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 140%;
  white-space: nowrap;
  cursor: pointer;
  transition: 0.3s;
  width: fit-content;
  &:hover {
    color: #1daf5c;
    background-image: linear-gradient(87.6deg, #1F2A3E 0.11%, #0F357A 100%);
  }
  img {
    margin-right: 10px;
    width: 20px;
    height: 20px;
  }
  @media (max-width: 800px) {
    font-size: 16px;
    font-weight: 700;
  }
  @media (max-width: 800px) {
    padding: 16px 24px;
  }
  @media (max-width: 560px) {
    width: 100%;
  }
`;

export const StyledPrimaryButton = styled.div`
    ${(props) => `
    width: ${props.useFit ? 'fit-content' : 'auto'}
    `};
  @media (max-width: 560px) {
    width: 100%;
  }
`
