import styled from "styled-components";

export const StyledButton = styled.button`
  border: none;
  background-color: #fff;
  padding: 12px 0;
  font-family: "Sofia Pro", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 140%;
  color: rgba(33, 58, 43, 0.4);
  margin-right: 30px;
  line-height: 140%;
  box-sizing: border-box;
  border-bottom: 2px solid transparent;
  cursor: pointer;
  transition: 0.3s;
  
  &:hover {
    color: #213a2b;
    border-bottom: 2px solid #213A2B;
  }
  @media (max-width: 1180px) {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
  }
  ${(props) =>
    props.active &&
    `
    color: #213a2b;
    border-bottom: 2px solid #213A2B;
    `}
`;
