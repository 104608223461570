import ReactDOM from 'react-dom';
import { useEffect, useRef } from 'react';
import cn from 'classnames';
import './MobileInterface.css';
import CrossIcon from "../../assets/icons/CrossIcon";

export function MobileInterfaceV2({
                                      onPanelClose,
                                      children,
                                      isActive,
                                      overlayClassName,
                                      rootClassName,
                                      headerText,
                                  }) {
    const el = useRef(document.createElement('div'));

    const onClose = () => {
        if (onPanelClose) onPanelClose();
    };

    useEffect(() => {
        const current = el.current;
        document.body.appendChild(current);

        return () => {
            document.body.removeChild(current);
        };
    }, []);

    return ReactDOM.createPortal(
         isActive && (
            <div
                style={{ background: 'rgba(0, 0, 0, 0.25)' }}
                className={cn('h-screen z-[10101] w-screen fixed top-0', {
                    [overlayClassName]: overlayClassName,
                    ['animate-modal-mobile-show-overlay']: isActive,
                })}
            >
                <div
                    className={cn('bg-white flex flex-col z-[10102] w-screen origin-bottom', {
                        [rootClassName]: rootClassName,
                        ['animate-modal-mobile-show-inner']: isActive,
                    })}
                >
                    <header
                        className={cn('flex h-fit justify-between items-center p-4 border-b-2', {
                            ['hidden']: !headerText,
                        })}
                    >
                        <div className="font-bold text-[16px]">{headerText || ''}</div>
                        <button type="button" className="flex justify-end w-10" onClick={onClose}>
                            <CrossIcon width="12" height="12" className="w-3 h-3 fill-[#219653]" />
                        </button>
                    </header>
                    {children}
                </div>
            </div>
        ),
        el.current,
    );
}
