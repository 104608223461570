import styled from "styled-components";

export const StyledFooter = styled.footer`
  background: #333d37;
  padding: 100px 0 0;
  @media (max-width: 870px) {
    flex-direction: column;
    padding: 40px 0 40px;
  }
`;
export const StyledContent = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 100px;
  @media (max-width: 880px) {
    flex-direction: column;
  }
`;
export const StyledEmail = styled.div`
  display: flex;
  @media (max-width: 950px) {
    flex-direction: column;
  }
`;
export const StyledLeftBlock = styled.div`
  margin-left: 23px;
  display: grid;
  gap: 0.5rem;

  p {
    max-width: 370px;
    font-family: "Gerbera";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 29px;
    color: #ffffff;
    opacity: 0.7;
  }
  p:first-child {
    font-weight: 700;
    line-height: 40px;
    color: #ffffff;
    opacity: 1;
  }
  p:nth-child(3) {
    margin: 15px 0;
  }
  @media (max-width: 950px) {
    margin-left: 0;
    margin-bottom: 40px;
  }
`;
export const StyledCentrBlock = styled.div`
  margin-left: 127px;
  display: flex;
  flex-direction: column;
  a {
    font-family: "Gerbera";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 200%;
    text-decoration: underline;
    color: #ffffff;
  }
  @media (max-width: 1000px) {
    margin-left: 20px;
  }
  @media (max-width: 950px) {
    margin-left: 0;
    margin-bottom: 50px;
  }
`;
export const StyledRightBlock = styled.div`
  max-width: 160px;
  p {
    font-family: "Gerbera";
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 160.44%;
    color: #ffffff;
  }
  img {
    width: 36px;
    margin-right: 12px;
  }
  @media (max-width: 870px) {
    p {
      margin-top: 20px !important;
    }
  }
`;
export const StyledCopyright = styled.div`
  display: flex;
  justify-content: space-around;
  padding: 20px 0;
  p {
    cursor: pointer;
    font-family: "Gerbera";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #9f9f9f;
    text-decoration: underline;
  }
  p:first-child {
    cursor: default;
    text-decoration: none;
  }
  @media (max-width: 870px) {
    flex-direction: column;
    p {
      margin-bottom: 26px;
    }
    p:last-child {
      margin-bottom: 0;
    }
  }
`;

export const StyledBorder = styled.div`
  border: 1px solid #fff;
  opacity: 0.1;
`;
