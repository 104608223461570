import React, { useEffect, useMemo, useState } from "react";
import CloseModal from "../../assets/img/close-modal.png";
import {
  StyledPopup,
  StyledModal,
  StyledModalHeader,
  StyledModalContent,
  StyledPopupButton,
  StyledPasswordInput,
  StyledError,
  StyledModalBody,
  StyledModalFooter,
} from "./styles";
import { useDebounce } from "../../hooks";
import { apiUrl } from "../../utils";
import axios from "axios";
import LoaderSpinner from "../LoaderSpinner";
/**
* Компонент, который будет виден при регистрации пользователя И в случае, если он НЕ был зарегистрирован ранее
*
* @param {{
*   onSuccess(): void,
*   onCloseModal(): void,
*   isOpened: boolean,
*   username: string,
*   email: string,
* }} props
* @param props.onCloseModal Функция, которая будет вызвана при закрытии модального окна 
* @param props.isOpened Флаг, определяющий видимость компонента
* @param props.username Имя пользователя, которое будет отправлено на сервер после успешного придумывания пароля
* @param props.email Почта пользователя, которая будет отправлена на сервер после успешного придумывания пароля
* */
export const RegisterPopup = ({ onSuccess, onCloseModal, isOpened, username, email }) => {
  const [password, setPassword] = useState("")
  const [repeatPassword, setRepeatPassword] = useState("")
  const [errorStatus, setErrorStatus] = useState()
  const [isDisabled, setIsDisabled] = useState(true)
  const [isPending, setIsPending] = useState(false)

  const minPasswordLength = 6

  const validationMap = {
    errorMinPasswordLength: {
      status: "validation",
    },
    errorPasswordsAreNotTheSame: {
      status: "passwords"
    },
    errorOnlyMainPassword: {
      status: "only-main",
    },
    errorOnlyRepeatPassword: {
      status: "only-repeat",
    },
  }
  /**
  * @param {import("react").KeyboardEvent<HTMLInputElement>} props
  * */
  const onChangePassword = ({ target }) => {
    if (target.value === "") setPassword(undefined)

    setPassword(target.value)
  }
  /**
  * @param {import("react").KeyboardEvent<HTMLInputElement>} props
  * */
  const onChangeRepeatPassword = ({ target }) => {
    if (target.value === "") setRepeatPassword(undefined)

    setRepeatPassword(target.value)
  }

  const onSubmit = () => {
    const url = `${apiUrl}/auth/register?email=${email}&name=${username}&password=${password}`

    setIsPending(true)

    axios.post(url).then(({ data }) => {
      if (data) {
        onSuccess()
      }

      setIsPending(false)
    });
  }

  const computedErrors = useMemo(() => {
    if (errorStatus === validationMap.errorMinPasswordLength.status) {
      return "Пароль должен быть более 6 символов";
    }

    if (errorStatus === validationMap.errorPasswordsAreNotTheSame.status) {
      return "Пароли не совпадают";
    }

    if (errorStatus === validationMap.errorOnlyRepeatPassword.status) {
      return "Пароли не совпадают";
    }

    if (errorStatus === validationMap.errorOnlyMainPassword.status) {
      return "Повторно введите пароль";
    }

    return "";
  }, [errorStatus, password, repeatPassword])

  useDebounce({
    callback() {
      if (password && repeatPassword) {
        if (password.length >= minPasswordLength) {
          if (repeatPassword === password) {
            setIsDisabled(false)
            setErrorStatus(undefined)
          } else {
            setErrorStatus(validationMap.errorPasswordsAreNotTheSame.status)
            setIsDisabled(true)
          }
        } else {
          setErrorStatus(validationMap.errorMinPasswordLength.status)
          setIsDisabled(true)
        }
      } else if (password) {
        if (password.length < minPasswordLength) {
          setErrorStatus(validationMap.errorMinPasswordLength.status)
          setIsDisabled(true)
        } else if (password && !repeatPassword) {
          setErrorStatus(validationMap.errorOnlyMainPassword.status)
          setIsDisabled(true)
        } else if (repeatPassword && repeatPassword !== password) {
          setErrorStatus(validationMap.errorPasswordsAreNotTheSame.status)
          setIsDisabled(true)
        }
      } else if (!password && repeatPassword) {
        setErrorStatus(validationMap.errorOnlyRepeatPassword.status)
        setIsDisabled(true)
      } else if (!repeatPassword && !password) {
        setErrorStatus(undefined)
        setIsDisabled(true)
      }
    },
    timeout: 300,
    deps: [password, repeatPassword, errorStatus, isDisabled]
  })

  useEffect(() => {
    return () => {
      setIsDisabled(false)
      setErrorStatus(undefined)
      setPassword("")
      setRepeatPassword("")
    }
  }, [])

  return (
    <StyledPopup
      modal
      lockScroll
      closeOnDocumentClick
      open={isOpened}
      onClose={onCloseModal}
    >
      <LoaderSpinner isWithOverlay isVisible={isPending} style={{ height: '8rem', width: '8rem' }} spinnerColor="#219653" spinnerBorderWidth="1rem" />
      <StyledModal>
        <StyledModalHeader>
          <p>Придумайте пароль и получите бесплатный доступ к WeCheck на 24 часа</p>
          <img onClick={() => onCloseModal()} src={CloseModal} alt="no img" />
        </StyledModalHeader>
        <StyledModalContent>
          <StyledModalBody>
            <StyledPasswordInput
              onChange={onChangePassword}
              placeholder="Введите пароль"
            />
            <StyledPasswordInput
              onChange={onChangeRepeatPassword}
              placeholder="Повторите пароль"
            />
            <StyledError isVisible={errorStatus !== undefined}>
              {computedErrors}
            </StyledError>
          </StyledModalBody>
          <StyledModalFooter>
            <StyledPopupButton
              disabled={isDisabled}
              onClick={onSubmit}
            >
              Сохранить пароль
            </StyledPopupButton>
          </StyledModalFooter>
        </StyledModalContent>
      </StyledModal>
    </StyledPopup>
  );
};
