import React from "react";
import { StyledButton, StyledSale } from "./RateButtonStyle";

export const RateButton = ({
  children,
  sale,
  active,
  handleClick,
  ...rest
}) => {
  return (
    <StyledButton {...rest} onClick={handleClick} active={active} sale={sale}>
      {children}
      {sale && <StyledSale active={active}>{sale}</StyledSale>}
    </StyledButton>
  );
};
