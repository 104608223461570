import React from "react";
import {hydrateRoot, createRoot} from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import YMInitializer from "react-yandex-metrika/lib/init";
import './index.css';

export const yandexMetricaClientID = 54208747;

const domNode = document.getElementById('root');
const root = createRoot(domNode);

YMInitializer([yandexMetricaClientID], { webvisor: true }, '2');

if (domNode.hasChildNodes()) {
  hydrateRoot(domNode,
    <React.StrictMode>
      <App />
    </React.StrictMode>
  );
} else {
  root.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>
  );
}

reportWebVitals();
