import styled from "styled-components";
import Play from "../../assets/img/cursor.png";

export const StyledCardContainer = styled.div`
  padding: 40px;
  box-sizing: border-box;
  background: #fff;
  position: relative;
  border-radius: 12px;
  ${(props) =>
    props.video ? `cursor: url(${Play}) 44 44, auto;` : "cursor: pointer;"}
  ${(props) =>
    props.video &&
    "background: linear-gradient(0deg, rgba(0, 116, 95, 0.96), rgba(0, 116, 95, 0.96));"};

  @media (max-width: 480px) {
    padding: 30px;
  }
  @media (max-width: 480px) {
    padding: 26px;
  }
  transition: 0.3s;
  &:hover {
    box-shadow: 0 4px 40px rgba(39, 150, 83, 0.25);
    ${(props) =>
        props.video &&
        "background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), linear-gradient(0deg, rgba(0, 116, 95, 0.96), rgba(0, 116, 95, 0.96));"}
  }
`;
export const StyledPlayButtonGroup = styled.div`
  display: flex;
  align-items: center;
  transition: 0.3s;
  img {
    width: 66px;
  }
  p {
    color: #fff;
    padding: 10px 0 10px 20px;
    margin-left: 20px;
    border-left: 1px solid rgba(255, 255, 255, 0.2);
  }
  ${StyledCardContainer}:hover & {
    opacity: 0;
  }
  @media (max-width: 560px) {
    p {
      padding: 6px 0 6px 20px;
    }
  }
`;
export const StyledTitleVideo = styled.p`
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 44px;
  letter-spacing: -0.3px;
  color: #fff;
  margin-top: 117px;

  @media (max-width: 870px) {
    font-size: 26px;
    line-height: 31px;
  }
  @media (max-width: 560px) {
    margin-top: 120px;
    font-size: 20px;
  }
`;

export const StyledTitle = styled.p`
  padding-bottom: 20px;
  margin: 20px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 44px;
  letter-spacing: -0.3px;
  color: #213a2b;
  &::after {
    content: "›";
    opacity: 0;
    color: #279d5a;
    margin-left: 5px;
  }
  ${StyledCardContainer}:hover & {
    transition: 0.3s;
    //color: #279d5a;
    //&::after {
    //  content: "›";
    //  opacity: 1;
    //  margin-left: 5px;
    //}
  }
  @media (max-width: 870px) {
    font-size: 26px;
    line-height: 31px;
  }
  @media (max-width: 560px) {
    font-size: 20px;
    padding-bottom: 12px;
    margin: 12px 0;
    &::after {
      content: "›";
      opacity: 1;
      color: #1f202a;
      margin-left: 5px;
    }
  }
`;

export const StyledPlay = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 66px !important;
  height: 66px;
  background: #ffffff;
  box-shadow: 0 5px 25px rgba(14, 136, 67, 0.41);
  border-radius: 100px;
  svg {
    width: 20px;
    height: 20px;
    margin-left: 5px;
  }
`;

export const StyledText = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  color: #396149;
  @media (max-width: 480px) {
    font-size: 16px;
  }
`;

export const StyledImgContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: #0A7965;
  border-radius: 32px;
  margin-top: ${props => props.imagePosition === 'right' ? '0' : '49px'};
  img {
    width: 100%;
    height: auto;
  }
  @media (max-width: 560px) {
    display: none;
  }
`;

export const StyledContentWrapper = styled.div`
  display: ${props => props.imagePosition === 'right' ? 'flex' : 'block'};
  width: 100% !important;
  @media (max-width: 560px) {
    display: block;
  }
`

export const StyledContentInfo = styled.div`
  flex-basis: ${props => props.imagePosition === 'right' ? '50%' : '100%'};
  padding-right: ${props => props.imagePosition === 'right' ? '20px' : '0'};
  width: 100% !important;
  @media (max-width: 560px) {
    padding-right: 0;
  }
`

export const StyledImageWrapper = styled.div`
  padding-left: ${props => props.imagePosition === 'right' ? '20px' : '0'};
  flex-grow: 1;
  @media (max-width: 560px) {
    padding-left: 0;
  }
`

export const StyledImgContainerMob = styled.div`
  display: none;
  justify-content: center;
  align-items: center;
  background: #f1fdf6;
  border-radius: 32px;
  margin-top: 16px;
  img {
    width: 100%;
    height: auto;
  }
  @media (max-width: 560px) {
    display: flex;
  }
`;

export const StyledInfoPoint = styled.div`
  display: flex;
  margin-bottom: 20px;
  @media (max-width: 560px) {
    margin-bottom: 0;
    border-bottom: ${props => !props.isLast ? '1px solid rgba(0, 0, 0, 0.06)' : 'none'};
    padding-bottom: ${props => props.isLast ? 0 : '12px'};
    padding-top: ${props => props.index === 0 ? 0 : '12px'};
  }
`

export const StyledInfoPointImage = styled.img`
  align-self: center;
  margin-right: 20px;
  width: 13px;
`

export const StyledInfoPointText = styled.div`
  padding-left: 20px;
  border-left: 1px solid rgba(0, 0, 0, 0.06);
  color: #396149;
`
