import { TOOLS } from './constants';
import React, {useEffect, useState} from "react";
import ArrowRightIcon from "../../assets/icons/ArrowRightIcon";
import ClickIcon from "../../assets/icons/ClickIcon";
import {MobileInterfaceV2} from "../MobileInterface/MobileInterface";
import {ImgPopup, RequestPopup} from "../../elements";
import gtag from "ga-gtag";
import ym from "react-yandex-metrika";
import LoopIcon from "../../assets/icons/LoopIcon";
import cn from "classnames";
import {NewExtBlock} from "../ExtSetupBlock/NewExtBlock";

const NewToolsSection = () => {
    const [activeTool, setActiveTool] = useState(TOOLS[0].id);
    const [activeSubTool, setActiveSubTool] = useState(TOOLS[0].subTools[0].id);
    const [isClicked, setIsClicked] = useState(false);
    const activeInfo = TOOLS.find(el => el.id === activeTool);
    const { subTools } = activeInfo;
    const activeSubInfo = subTools.find(el => el.id === activeSubTool);
    const activeSubIndex = subTools.findIndex(el => el.id === activeSubTool);
    const [openDemon, setOpenDemon] = useState(
        window.location.hash === "#demonstration_modal"
    );

    const closeModal = () => {
        setOpenDemon(false);
        window.location.hash === "";
    };
    return (
        <div className="p-4 lg:py-[80px] lg:px-[120px] bg-white">
            <RequestPopup
                type={'demonstration'}
                title={'Запрос демонстрации'}
                closeModal={closeModal}
                open={openDemon}
                tag={() => {
                    gtag("event", "form_send", { form: "doubt_demo_form" });
                    gtag("event", "form_send", { form: "demo_form_all" });
                    gtag("event", "form_send", { form: "all_leads" });
                }}
                tag2={() => {
                    ym("reachGoal", "doubt_demo_form");
                    ym("reachGoal", "demo_form_all");
                    ym("reachGoal", "all_leads");
                }}
            />
            <span className="block font-bold text-2xl leading-[37px] lg:leading-[57px] lg:text-5xl text-[#213A2B]">
                39 инструментов для увеличения продаж
            </span>
            <span className="block lg:hidden text-[#333D37]/50 my-4 text-base leading-[26px]">
                Убедитесь в полезности сервиса. Попробуйте все инструменты для увеличения прибыли от WeCheck
            </span>
            <div className="lg:mt-[60px] flex space-x-2 lg:space-x-6 py-4 lg:p-0 overflow-x-auto lg:overflow-hidden">
                {TOOLS.map((tool) => {
                    const { id, label, subTools } = tool;
                    const isActive = id === activeTool;
                    return (
                        <div key={id} onClick={() => {
                            setActiveTool(id);
                            setActiveSubTool(subTools[0].id);
                        }} style={{backgroundColor: isActive ? 'rgba(0, 116, 95, 0.03)' : ''}}
                             className={`px-4 py-3 lg:py-4 lg:px-5 border ${ isActive ? '!border-[#00745F]' : ''} rounded-xl lg:w-1/4 w-min cursor-pointer hover:border-gray-400`}>
                            <span className={`block text-lg ${isActive ? 'font-bold text-[#00745F]' : ''}`}>
                                {label}
                            </span>
                            <span className="block text-[#6B6C6E] lg:leading-[28px] whitespace-nowrap text-xs mt-1 lg:mt-0">
                                {subTools.length} инструментов
                            </span>
                        </div>
                    )
                })}
            </div>
            <div className="lg:hidden mt-4">
                <div className="border-y border-[#EAEAEA] divide-y">
                    {subTools.map(({number, id, label}) => {
                        const isActiveSub = activeSubTool === id;
                        return (
                            <SubToolMobile key={id} id={id} number={number} label={label} isActiveSub={isActiveSub}
                                     setActiveSubTool={setActiveSubTool} setIsClicked={setIsClicked}/>
                        )
                    })}
                </div>
                {
                    isClicked &&
                    <MobileInterfaceV2
                        isActive={isClicked}
                        onPanelClose={() => setIsClicked(false)}
                        rootClassName="-bottom-0 absolute"
                        headerText={
                            <div className="flex space-x-2 lg:space-x-3 text-xs lg:text-base leading-[28px]">
                                        <span className={`block text-[#213A2B]/30`}>
                                            {activeSubInfo.number}
                                        </span>
                                <span className={`block text-[#213A2B]/60`}>{activeSubInfo.label}</span>
                            </div>
                        }
                    >
                       <div className="px-6 mt-3">
                           <span className="block text-[20px] leading-[27px] text-[#213A2B] font-extrabold">
                               {activeSubInfo.title}
                           </span>
                           <span className="block mt-2 text-[#333D37]/50">
                                {activeSubInfo.subTitle}
                           </span>
                           <div className="flex border border-[#EAEAEA] rounded-lg p-2.5 mb-4 mt-3 justify-center">
                               {/*<button className="flex items-center space-x-2 border-b border-[#EAEAEA] w-full pb-2.5">*/}
                               {/*    <svg width="14" height="16" viewBox="0 0 27 28" fill="none" xmlns="http://www.w3.org/2000/svg">*/}
                               {/*        <path d="M1 24.7101V3.28991C1 1.79027 2.58932 0.824092 3.92063 1.5144L24.5758 12.2245C26.015 12.9707 26.015 15.0293 24.5758 15.7755L3.92063 26.4856C2.58932 27.1759 1 26.2097 1 24.7101Z" stroke="#00745F" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>*/}
                               {/*    </svg>*/}
                               {/*    <span className="block text-[#00745F] text-[12px] leading-[24px] font-medium">*/}
                               {/*         Смотреть видео*/}
                               {/*     </span>*/}
                               {/*</button>*/}
                               <div className="flex pt-2.5 w-fit">
                                   <button className="flex items-center space-x-2" onClick={() => setOpenDemon(true)}>
                                       <ClickIcon width="24" height="24" />
                                       <span className="block text-[#00745F] text-[12px] leading-[24px] font-medium">
                                        Попробовать
                                    </span>
                                   </button>
                                   <div className="bg-[#F4D19E]/20 rounded-[7px] px-2 ml-2">
                                    <span className="block text-xs leading-[24px] text-[#7F694A]">
                                        Бесплатно
                                    </span>
                                   </div>
                               </div>
                           </div>
                       </div>
                        <Images images={activeSubInfo.images} />
                        <div className="flex border-t border-[#EAEAEA]">
                            <button className="px-4 py-[22px] border-r border-[#EAEAEA]" onClick={() => {
                                if (activeSubIndex !== 0) {
                                    setActiveSubTool(subTools[activeSubIndex - 1].id)
                                }
                            }}>
                                <ArrowRightIcon width="20" height="20" className="-rotate-180"/>
                            </button>
                            <button className="flex items-center justify-between grow"
                                    onClick={() => {
                                        if (activeSubIndex < subTools.length - 1) {
                                            setActiveSubTool(subTools[activeSubIndex + 1].id)
                                        }
                                    }}>
                                <div className="pl-4">
                                       <span className="block text-xs text-[#333D37]/50">
                                        Следующий инструмент
                                       </span>
                                    <span className="block text-sm leading-[24px] text-[#00745F] font-medium">
                                            {subTools[activeSubIndex + 1]?.label || ''}
                                        </span>
                                </div>
                                <div className="px-4 py-[22px]">
                                    <ArrowRightIcon width="20" height="20"/>
                                </div>
                            </button>
                        </div>

                    </MobileInterfaceV2>
                }
            </div>
            <div className="space-x-6 mt-8 hidden lg:flex">
                {subTools.length !== 1 &&
                    <div className="min-w-[440px] border border-[#AFB0C0] py-6 px-8 rounded-xl h-[616px]"
                         style={{backgroundColor: 'rgba(175, 176, 192, 0.06)'}}>
                        <div className="space-y-2">
                            {subTools.map(({number, id, label}) => {
                                const isActiveSub = activeSubTool === id;
                                return (
                                    <SubTool key={id} id={id} number={number} label={label} isActiveSub={isActiveSub}
                                             setActiveSubTool={setActiveSubTool}/>
                                )
                            })}
                        </div>
                    </div>
                }
                <div className="flex flex-col grow border border-[#E0E0E0] rounded-xl">
                    <div className="px-8 pt-6 w-full">
                        <div>
                            <span className="block text-2xl leading-[30px] mb-1 text-[#213A2B] font-extrabold">
                                {activeSubInfo.title}
                            </span>
                            <span className="block text-[#898A8C] text-base">
                                {activeSubInfo.subTitle}
                            </span>
                            <div className="w-full h-px bg-[#EAEAEA] mt-4"/>
                            <div className="flex items-center">
                                {/*<button className="flex items-center space-x-2">*/}
                                {/*    <svg width="14" height="16" viewBox="0 0 27 28" fill="none" xmlns="http://www.w3.org/2000/svg">*/}
                                {/*        <path d="M1 24.7101V3.28991C1 1.79027 2.58932 0.824092 3.92063 1.5144L24.5758 12.2245C26.015 12.9707 26.015 15.0293 24.5758 15.7755L3.92063 26.4856C2.58932 27.1759 1 26.2097 1 24.7101Z" stroke="#00745F" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>*/}
                                {/*    </svg>*/}
                                {/*    <span className="block text-[#00745F] font-[15px] leading-[24px] font-medium">*/}
                                {/*        Смотреть видео*/}
                                {/*    </span>*/}
                                {/*</button>*/}
                                {/*<div className="mx-4 w-px h-[24px] bg-[#EAEAEA]"/>*/}
                                <button className="flex py-[15px] mb-2 border border-white hover:border-[#00745F] rounded-lg" onClick={() => setOpenDemon(true)}>
                                    <div className="flex items-center space-x-2">
                                        <ClickIcon width="24" height="24" />
                                        <span className="block text-[#00745F] leading-[24px] font-medium">
                                            Попробовать
                                        </span>
                                    </div>
                                    <div className="bg-[#F4D19E]/20 rounded-[7px] px-2 ml-2">
                                        <span className="block text-xs leading-[24px] text-[#7F694A]">
                                            Бесплатно
                                        </span>
                                    </div>
                                </button>
                            </div>
                        </div>
                    </div>
                    <Images images={activeSubInfo.images} />
                </div>
            </div>
            <NewExtBlock />
        </div>
    )
}

const SubTool = ({ id, number, label, isActiveSub, setActiveSubTool}) => {
    const [hover, setIsHover] = useState(false)
    return (
        <div onClick={() => setActiveSubTool(id)} onMouseEnter={() => setIsHover(true)} onMouseLeave={() => setIsHover(false)}
             className={`${isActiveSub ? 'text-[#00745F]' : ''} flex justify-between items-center hover:!text-gray-400 cursor-pointer`} key={id}>
            <div className="flex space-x-3 text-base leading-[28px]">
                                        <span className={`block w-5 ${isActiveSub ? '!text-[#00745F]' : hover ? 'text-gray-500' : 'text-[#213A2B]/30'}`}>
                                            {number}
                                        </span>
                <span className={`block ${isActiveSub ? '!text-[#00745F]' : hover ? 'text-gray-500' : 'text-[#213A2B]/60'}`}>
                                            {label}
                                        </span>
            </div>
            {isActiveSub &&
                <ArrowRightIcon width="10" height="10" className="text-[#00745F]"/>
            }
        </div>
    )
}

const SubToolMobile = ({ id, number, label, isActiveSub, setActiveSubTool, setIsClicked}) => {
    return (
        <div onClick={() => {
            setActiveSubTool(id);
            setIsClicked(true);
        }}
             className={`${isActiveSub ? 'text-[#00745F]' : ''} flex justify-between items-center hover:!text-gray-400 cursor-pointer py-1`} key={id}>
            <div className="flex space-x-2 lg:space-x-3 text-xs lg:text-base leading-[28px]">
                                        <span className={`block text-[#213A2B]/30`}>
                                            {number}
                                        </span>
                <span className={`block text-[#213A2B]/60`}>{label}</span>
            </div>
             <ArrowRightIcon width="10" height="10" className="text-[#00745F]"/>
        </div>
    )
}

const Images = ({images = []}) => {
    const [activeImage, setActiveImage] = useState(0);
    const [hovered, setIsHovered] = useState(false);
    const [open, setOpen] = useState(false);
    const [isImageLoading, setIsImageLoading] = useState(false);
    const [imgSrc, setImgSrc] = useState('');
    const closeModal = () => setOpen(false);
    const handleClickPrev = () => {
        if (activeImage === 0) {
            setActiveImage(images.length - 1)
        } else {
            setActiveImage(activeImage - 1)
        }
    }
    const handleClickNext = () => {
        if (activeImage === images.length - 1) {
            setActiveImage(0)
        } else {
            setActiveImage(activeImage + 1)
        }
    }
    useEffect(() => {
        setIsImageLoading(true)
        const img = new Image();
        img.src = images[activeImage];
        img.onload = () => {
            setIsImageLoading(false)
            setImgSrc(images[activeImage]);
        };
    }, [images[activeImage]]);
    useEffect(() => {
        setActiveImage(0)
    }, [images])
    return (
        <div className="w-full flex flex-col grow relative items-center justify-center lg:rounded-b-lg  "
             onMouseEnter={() => setIsHovered(true)}
             onMouseLeave={() => setIsHovered(false)}
             // style={{background: 'radial-gradient(16299.33% 72472.87% at -9.71% 112.08%, #F7E2F3 0%, #F9E6F8 18.42%, #E8E1FD 40.32%, #D6E1FF 61.97%, #E6F0FF 100%)'}}
        >
            <ImgPopup
                img={imgSrc}
                closeModal={() => closeModal()}
                open={open}
                title={'Скриншон инструмента'}
            />
            {
                isImageLoading ?
                    <div className="w-full lg:max-h-[405px] max-h-[200px] lg:rounded-b-lg"/> :
                    <img src={imgSrc} className="w-fit lg:max-h-[405px] max-h-[200px] lg:rounded-b-lg" alt="Screenshot"/>
            }
            <div className={cn("absolute flex justify-between items-center h-full w-full lg:rounded-b-lg",{
                'bg-[#282828]/20': hovered
            })}>
                <div className="">
                    {images.length !== 1 &&
                    <button className="flex items-center justify-center z-20 rounded-full w-[38px] h-[38px] !bg-white text-[#219653] border border-black/12 ml-3"
                        onClick={handleClickPrev}>
                        <ArrowRightIcon width={14} height={14} className="-rotate-180 " />
                    </button>
                    }
                </div>
                { hovered &&
                    <div>
                        <button className="flex items-center justify-center z-20 rounded-full p-4 !bg-white text-[#219653] border border-black/12"
                                onClick={() => setOpen(true)}>
                            <LoopIcon width={24} height={25} />
                            <span className="block ml-3 text-base">
                            Посмотреть скрин
                        </span>
                        </button>
                    </div>
                }

                <div className="">
                    {images.length > 1 &&
                    <button className="flex items-center justify-center z-20 rounded-full w-[38px] h-[38px] !bg-white text-[#219653] border border-black/12 mr-3"
                    onClick={handleClickNext}>
                        <ArrowRightIcon width={14} height={14}  />
                    </button>
                    }
                </div>
            </div>
        </div>
    )
}

export default NewToolsSection;