import {
  StyledModal,
  StyledModalContent,
  StyledModalHeader, StyledPopup,
  StyledPopupButton
} from "../../elements/EmailPopup/EmailPopupStyle";
import CloseModal from "../../assets/img/close-modal.png";
import React from "react";

const ConfirmSubscribeModal = ({ closeModal }) => {
  return (
    <StyledPopup
      lockScroll={true}
      open={true}
      closeOnDocumentClick
      onClose={closeModal}
      modal
    >
      <StyledModal>
        <StyledModalHeader>
          <p>Спасибо что подписались</p>
          <img onClick={() => closeModal()} src={CloseModal} alt="no img" />
        </StyledModalHeader>
        <StyledModalContent>
          <p>Лучшие новости, исследования и аналитика из мира маркетплейсов на вашей почте. <br /> Только полезное и без спама.</p>
          <StyledPopupButton onClick={() => closeModal()}>
            Ура, спасибо
          </StyledPopupButton>
        </StyledModalContent>
      </StyledModal>
    </StyledPopup>
  )
};

export default ConfirmSubscribeModal;

