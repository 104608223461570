import { CheckboxContainer, CheckboxLabel, CheckMark, HiddenCheckbox, StyledCheckbox } from "./CheckboxStyle";

const Checkbox = ({ className, checked, label, name, onChange, ...props }) => (
  <CheckboxContainer onClick={onChange} className={className}>
    <HiddenCheckbox id={name} checked={checked} {...props} />
    <StyledCheckbox checked={checked}>
      <CheckMark height="14" viewBox="0 0 17 14" fill="#FFFFFF">
        <path fillRule="evenodd" clipRule="evenodd" d="M1.77741 7.81664C1.47426 7.47996 0.955583 7.45278 0.618905 7.75593C0.282226 8.05907 0.255043 8.57776 0.55819 8.91443L4.42256 13.2063C4.72571 13.5429 5.24439 13.5701 5.58107 13.267C5.91775 12.9638 5.94493 12.4451 5.64179 12.1085L1.77741 7.81664Z" />
        <path fillRule="evenodd" clipRule="evenodd" d="M14.2363 1.2351C14.5394 0.898421 15.0581 0.871237 15.3948 1.17438C15.7314 1.47753 15.7586 1.99621 15.4555 2.33289L5.67224 13.1983C5.36909 13.535 4.85041 13.5621 4.51373 13.259C4.17705 12.9558 4.14987 12.4372 4.45302 12.1005L14.2363 1.2351Z" />
      </CheckMark>
    </StyledCheckbox>
    <CheckboxLabel>
      <label htmlFor={name}>
        {label}
      </label>
    </CheckboxLabel>
  </CheckboxContainer>
)

export default Checkbox;