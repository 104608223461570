import React, { useState } from "react";
import { StyledModal, StyledModalContent } from "./CookiesStyle";

export const Cookies = () => {
  const localeValue = localStorage.getItem('useCookies')
  const [open, setOpen] = useState(localeValue !== 'false');

  const closeModal = () => {
    setOpen(false);
    localStorage.setItem('useCookies', 'false')
  };
  return (
    <StyledModal isOpen={open}>
      <StyledModalContent>
        <p>
          Мы храним <span>cookie-файлы</span>, чтобы вам было удобно
          пользоваться сайтом.
        </p>
        <p onClick={closeModal}>Хорошо</p>
      </StyledModalContent>
    </StyledModal>
  );
};
