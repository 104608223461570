import React, { useState } from "react";
import CloseModal from "../../assets/img/close-modal.png";
import {
  StyledPopup,
  StyledModal,
  StyledModalHeader,
  StyledModalImgContainer,
} from "./ImgPopupStyle";

export const ImgPopup = ({ img, closeModal, open, title, video }) => {
  const [isLoadedImage, setIsLoadedImage] = useState(false);

  return (
    <StyledPopup
      lockScroll={true}
      open={open}
      closeOnDocumentClick
      onClose={closeModal}
      modal
    >
          <StyledModal style={{ display: isLoadedImage || video ? 'block' : 'none'}}>
            <StyledModalHeader>
              <p>{title}</p>
              <img onClick={() => closeModal()} src={CloseModal} alt="no img"></img>
            </StyledModalHeader>
            {video ? (
                <iframe
                    width="800px"
                    height="400px"
                    src="https://vk.com/video_ext.php?oid=-211040288&id=456239025&hd=2&autoplay=1"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                ></iframe>
            ) : (
                <StyledModalImgContainer>
                  <img src={img} alt="no img" onLoad={() => {
                    setIsLoadedImage(true)
                  }}></img>
                </StyledModalImgContainer>
            )}
          </StyledModal>
    </StyledPopup>
  );
};
