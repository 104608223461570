import React, { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import styled from "styled-components";
import { Footer, HeaderComponent } from "./components";
import { Cookies } from "./elements";
import { GlobalStyle } from "./GlobalStyle";
import Agreement from "./pages/Agreement";
import HomePage from "./pages/HomePage";
import Licence from "./pages/Licence";
import Stock from "./pages/Stock";

const StyledMain = styled.main`
  // margin-top: 70px;
  @media (max-width: 740px) {
    margin-top: 0;
  }
`;

function App() {
  const [activeTab, setActiveTab] = useState("market");
  const rateRef = useRef();

  const handleScroll = () => {
    const scrollFromTop = window.pageYOffset;
    if (scrollFromTop >= rateRef?.current?.offsetTop - 30) {
      if (activeTab !== "tariff") {
        setActiveTab("tariff");
      }
    } else {
      if (activeTab !== "market") {
        setActiveTab("market");
      }
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [activeTab]);
  return (
    <React.Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="theme-color" content="#000000" />
        <meta
          name="description"
          content="Зарабатывайте больше, а рискуйте меньше"
        />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="WeCheck - аналитика маркетплейсов" />
        <meta property="og:site_name" content="wecheck.ru" />
        <meta property="og:url" content="https://wecheck.ru/" />
        <meta
          property="og:description"
          content="Зарабатывайте больше, а рискуйте меньше!"
        />
        <meta
          property="og:image"
          content="https://go.wecheck.ru/wecheck-logo.jpg"
        />
        <meta
          property="twitter:title"
          content="WeCheck - аналитика маркетплейсов"
        />
        <meta
          property="twitter:description"
          content="Зарабатывайте больше, а рискуйте меньше"
        />
        <meta
          property="twitter:image"
          content="https://go.wecheck.ru/wecheck-logo.jpg"
        />
        <meta property="twitter:card" content="summary" />
        <title>WeCheck - аналитика маркетплейсов</title>
      </Helmet>
      <BrowserRouter>
        <div>
          <HeaderComponent activeTab={activeTab} />

          <StyledMain>
            <Routes>
              <Route path="/" element={<HomePage rateRef={rateRef} />} />
              <Route path="/ecomexpo" element={<Stock />} />
              <Route path="/ecomexpo/:id" element={<Stock />} />
              <Route path="/agreement" element={<Agreement />} />
              <Route path="/licence" element={<Licence />} />
            </Routes>
          </StyledMain>
        </div>
        <Cookies />
        <Footer />
      </BrowserRouter>
      <GlobalStyle />
    </React.Fragment>
  );
}

export default App;
