import React from "react";
import { StyledButton } from "./NavButtonStyle";

export const NavButton = ({ children, link, active }) => {
  return (
    <StyledButton
      active={active}
      onClick={() => {
        window.open(link);
      }}
    >
      {children}
    </StyledButton>
  );
};
