import LineDoubtIcon from "../../assets/icons/LineDoubtIcon";
import Fire from "../../assets/img/fire3.png";
import MobileDoubt from "../../assets/img/mobileDoubt.png";
import gtag from "ga-gtag";
import ym from "react-yandex-metrika";
import {PrimaryButton} from "../../elements";
import {useState} from "react";
import NewDoubtIcon from "../../assets/icons/NewDoubtIcon";

const NewDoubtSection = () => {
    const [openDemon, setOpenDemon] = useState(
        window.location.hash === "#demonstration_modal"
    );

    const closeModal = () => {
        setOpenDemon(false);
        window.location.hash === "";
    };
    return (
        <div className="w-full lg:h-[657px] relative overflow-hidden block lg:flex items-center p-4 pb-10 lg:p-0 lg:pl-[120px]">
            <div className="absolute -left-[128px] top-[93px] z-10 hidden lg:block">
                <LineDoubtIcon className="absolute" width="1650" height="456"/>
            </div>
            <div className="absolute -left-[128px] top-[93px] z-10 lg:hidden">
                <LineDoubtIcon className="absolute" width="533" height="164"/>
            </div>
            <div className="mt-[24px] pb-5 lg:hidden">
                <img src={MobileDoubt} className="w-[160px] h-[126px]" />
            </div>
            <div className="hidden lg:block absolute z-20" style={{
                left: `calc(50% - 100px)`,
            }}>
                <NewDoubtIcon width="480" height="377"/>
            </div>
            <div className="block lg:flex z-20">
                <div>
                    <span className="text-[#213A2B] block font-bold text-2xl leading-[37px] lg:leading-[57px] lg:text-5xl">
                        Выдаем<br className="hidden lg:block"/> полный доступ<br/>на 3 дня
                    </span>
                    <span className="text-[#333D37]/50 text-lg leading-[26px] lg:mt-5 mt-4 block">
Убедитесь в полезности сервиса. Попробуйте все<br className="hidden lg:block"/>инструменты для увеличения прибыли от WeCheck
                    </span>
                    <div className="mt-5 lg:mt-[60px]">
                        <PrimaryButton
                            onClick={() => setOpenDemon(true)}
                            open={openDemon}
                            closeModal={() => closeModal()}
                            title="Запрос демонстрации"
                            type="demonstration"
                            icon={Fire}
                            tag={() => {
                                gtag("event", "form_send", { form: "doubt_demo_form" });
                                gtag("event", "form_send", { form: "demo_form_all" });
                                gtag("event", "form_send", { form: "all_leads" });
                            }}
                            tag2={() => {
                                ym("reachGoal", "doubt_demo_form");
                                ym("reachGoal", "demo_form_all");
                                ym("reachGoal", "all_leads");
                            }}
                            useFit
                        >
                            Заказать демонстрацию
                        </PrimaryButton>
                    </div>
                </div>
            </div>
            <div className="absolute"
                 style={{ top: 802, left: -257, background: '#279653', filter: 'blur(250px)', width: 748, height: 801}}/>
            <div className="absolute"
                 style={{ top:
                         846, left: 548, background: '#303F59', filter: 'blur(250px)', width:
                         454, height: 487}}/>
            <div className="absolute"
                 style={{ top: 792, left: 1010, background: '#279653', filter: 'blur(250px)', width: 687, height: 736}}/>
        </div>
    )
}

export default NewDoubtSection;