export const handlePhoneChange = (e, onChange) => {
        let content = e.target.value;
        if (!content) return;
        content = Array.from(content).filter((ltr) => ltr.charCodeAt(0) > 47 && ltr.charCodeAt(0) < 58);
        switch (content[0]) {
            case '8':
                content[0] = '7';
                break;
            case '9':
                content.unshift('7');
                break;
            default:
                break;
        }

        let [countryCode, operatorCode, number3, number21, number22] = [
            content[0],
            content.slice(1, 4).join(''),
            content.slice(4, 7).join(''),
            content.slice(7, 9).join(''),
            content.slice(9, 11).join(''),
        ];

        e.target.value = countryCode?.length ? `+${countryCode}` : '';
        if (operatorCode.length) e.target.value += ` (${operatorCode}`;
        if (number3.length) e.target.value += `) ${number3}`;
        if (number21.length) e.target.value += `-${number21}`;
        if (number22.length) e.target.value += `-${number22}`;
        onChange(e);
}