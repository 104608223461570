import EllipseIcon from "../../assets/icons/EllipseIcon";
import Laptop3 from "../../assets/img/Laptop3.png";
import React, {useState} from "react";
import FireIcon from "../../assets/img/fire3.png";
import gtag from "ga-gtag";
import ym from "react-yandex-metrika";
import {PrimaryButton} from "../../elements";
import LikeIcon from "../../assets/icons/LikeIcon";
import {useSearchParams} from "react-router-dom";


const ConsultationSection = () => {
    const [queryParams] = useSearchParams();
    const [openDemo, setOpenDemo] = useState(queryParams.get('showConsultation') === 'true');

    const closeModalDemo = () => {
        setOpenDemo(false);
        window.location.hash === "";
    };
    return (
        <div className="w-full lg:h-[638px] flex relative p-4 pb-10 lg:p-0" style={{background: 'radial-gradient(48.37% 48.37% at 31.22% 46.24%, #00866E 0%, #006452 100%)'}}>
            <div className="absolute left-[123px] z-10 hidden lg:block">
                <EllipseIcon width="888" height="638"/>
            </div>
            <div className="absolute -left-[17px] z-10 -top-[16px] block lg:hidden">
                <svg width="420" height="275" viewBox="0 0 320 275" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M214.249 200.059C163.523 236.031 111.428 259.854 68.2226 269.305C46.619 274.031 27.2629 275.158 11.4222 272.432C-4.41564 269.707 -16.7026 263.138 -24.2455 252.502C-31.7885 241.865 -33.9231 228.097 -31.2563 212.249C-28.5889 196.398 -21.1227 178.505 -9.51683 159.68C13.6938 122.034 53.408 80.7515 104.134 44.7796C154.86 8.80768 206.955 -15.0157 250.16 -24.467C271.764 -29.1929 291.12 -30.32 306.961 -27.5941C322.798 -24.8688 335.085 -18.3001 342.628 -7.66336C350.171 2.97342 352.306 16.7415 349.639 32.5893C346.972 48.4399 339.506 66.3336 327.9 85.1579C304.689 122.805 264.975 164.087 214.249 200.059Z" stroke="white" strokeOpacity="0.16"/>
                </svg>
            </div>
            <div className="block lg:flex items-center justify-center z-20 grow lg:space-x-20">
                <img src={Laptop3} alt="no img" className="w-[150px] h-[98px] lg:h-[385px] lg:w-[502px] mt-6 lg:mt-0"></img>
                <div>
                    <span className="block font-bold text-2xl leading-[37px] lg:leading-[57px] lg:text-5xl text-white border-b pb-4 lg:border-none lg:pb-0" style={{ borderColor: 'rgba(255, 255, 255, 0.12)'}}>
                        Закажите бесплатную<br /> консультацию <br className="lg:hidden"/>от WeCheck
                    </span>
                    <span className="block text-lg leading-[26px] text-white mt-5">
                        Наши аналитики продемонстрируют, как увеличить выручку на<br className="hidden lg:block"/> примере вашего бизнеса. После демонстрации вы получите полный<br className="hidden lg:block"/> доступ к платформе на 3 дня
                    </span>
                    <div className="mt-5 lg:mt-[60px]">
                        <PrimaryButton
                            title="Заказать консультацию"
                            onClick={() => setOpenDemo(true)}
                            open={openDemo}
                            closeModal={() => closeModalDemo()}
                            type="demonstration"
                            icon={FireIcon}
                            subText={<span className="flex text-white/60 items-center text-sm">
                                    <LikeIcon width="18" height="18"/>
                                <span className="block ml-2">
                                    Это бесплатно
                                </span>
                    </span>}
                            tag={() => {
                                gtag("event", "form_send", {
                                    form: "consulting_form",
                                });
                                gtag("event", "form_send", {
                                    form: "24_hour",
                                });
                                gtag("event", "form_send", {
                                    form: "all_leads",
                                });
                            }}
                            tag2={() => {
                                ym("reachGoal", "consulting_form");
                                ym("reachGoal", "24_hour");
                                ym("reachGoal", "all_leads");
                            }}
                        >
                            Заказать консультацию
                        </PrimaryButton>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ConsultationSection;