import React, { useState } from "react";
import {
  StyledSection,
  StyledContent,
  StyledText,
  StyledEmailInput,
  StyledInputContainer,
  StyledCheckInput,
  StyleButtonFix,
  StyledContainer,
  StyledInputSubmit,
} from "./ContactSectionStyle";
import axios from "axios";
import { EmailPopup } from "../../elements";
import gtag from "ga-gtag";
import { apiUrl } from "../../utils";
import { useLinks } from "../../hooks";

export const ContactSection = ({ className, text, innerClass }) => {
  const [email, setEmail] = useState("");
  const [open, setOpen] = useState(false);
  const [error, setError] = useState({ privacy: false });

  const { licenceLink } = useLinks()

  const closeModal = () => setOpen(false);

  const handleEmailSend = (e) => {
    const isPrivacyAccept = e.target[1].checked;
    const privacyName = e.target[1].name;
    e.preventDefault();

    if (isPrivacyAccept) {
      setError((prev) => {
        return {
          ...prev,
          [privacyName]: false,
        };
      });

      axios
        .post(
          `${apiUrl}/saveform?email=${email}&type=subscribe`
        )
        .then(() => {
          setOpen(true);
          gtag("event", "form_send", {
            form: "podpiska_form_succes",
          });
        });
    } else {
      setError((prev) => {
        return {
          ...prev,
          [privacyName]: true,
        };
      });
    }
  };

  return (
    <StyledSection id="contact_section" className={className}>
      <StyledContainer className="lg:!px-0">
        <StyledContent className={innerClass}>
          <StyledText>
            {text ? text : <>
              <p>Советы по аналитике.</p>
              <p>Тренды. Новости о маркетплейсах.</p>
              <p>Раз в неделю, без спама.</p>
            </>}
          </StyledText>
          <StyledInputContainer
            onSubmit={(e) => handleEmailSend(e)}
            id="email-form"
          >
            <StyledEmailInput
              required
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              type="email"
              placeholder="Введите ваш email ..."
              className={innerClass}
            ></StyledEmailInput>
            <StyledCheckInput>
              <label>
                <input name="privacy" type="checkbox"></input>
                  Принимаю
                <a href={licenceLink} style={{marginLeft: 6}}>
                  Политику конфиденциальности
                </a>
              </label>
              {error.privacy && <p>Пожалуйста, подтвердите согласие</p>}
            </StyledCheckInput>
          </StyledInputContainer>
          <EmailPopup
            title={"Запрос отправлен"}
            closeModal={closeModal}
            open={open}
          />
          <StyleButtonFix>
            <StyledInputSubmit
              value="Подписаться"
              type="submit"
              form="email-form"
              emailContact={true}
            ></StyledInputSubmit>
          </StyleButtonFix>
        </StyledContent>
      </StyledContainer>
    </StyledSection>
  );
};
