import { useLinks } from "../hooks";

const Agreement = () => {
  const { agreementLink, licenceLink } = useLinks();
  return (
    <div className="m-10">
      <h1 className="text-4xl mb-5 font-bold">Публичная оферта</h1>
      Дата опубликования текущей версии: 12 сентября 2024 г.
      <br />
      Данная публичная оферта(Оферта) является официальным предложением ООО
      &quot;Вичек&quot; (Лицензиара) заключить лицензионный договор в
      соответствии со ст. 437 ГК РФ.
      <br />
      Оферта адресована любому дееспособному лицу (Лицензиат) на изложенных ниже
      условиях.
      <br />
      Безусловным принятием(акцептом) условий Оферты считается регистрация в
      Сервисе.
      <br />
      Термины и определения
      <br />
      Сервис — сайт “Wecheck” (
      <a href="https://www.wecheck.ru">https://www.wecheck.ru/</a>), который
      принадлежит Лицензиару и предназначен для аналитики данных о других сайтах
      <br />
      Тариф — объем прав Лицензиата на Сервис, который определяет срок и способы
      его использования
      <br />
      Личный кабинет — персональный раздел Лицензиата в Сервисе.
      <br />‍<br />
      <strong>
        1. Предмет
        <br />
      </strong>
      1.1.Лицензиар предоставляет Лицензиату право использовать Сервис на
      условиях неисключительной лицензии.
      <br />‍<br />‍
      <strong>
        2. Условия использования Сервиса
        <br />
      </strong>
      2.1.Исключительное право на Сервис, а также на доработки функционала,
      совершенные в процессе исполнения лицензионного договора, принадлежит
      Лицензиару.
      <br />
      2.2. Лицензиат вправе использовать Сервис путем воспроизведения и
      способами, предусмотренными его функционалом.
      <br />
      2.3. Лицензиат вправе использовать Сервис на территории всего мира в
      течение срока, определяемого Тарифом.
      <br />
      2.4. Лицензиату запрещается осуществлять самостоятельно или
      разрешать/способствовать третьим лицам осуществлять следующие действия:
      <br />
      2.4.1. использовать Сервис более чем с 3 (трех) IP-адресов, более чем с
      1(одного) IP-адреса одновременно, более чем с 1 (одного) браузера
      одновременно;
      <br />
      2.4.2. распространять информацию, полученную в Сервисе без письменного
      согласия Лицензиара;
      <br />
      2.4.3. осуществлять доступ к Сервису с использованием средств
      автоматизации;
      <br />
      2.4.4. декомпилировать, дизассемблировать, дешифровать и производить иные
      действия с исходным кодом Сервиса.
      <br />
      2.4.5. Осуществлять инженерный анализ Сервиса.
      <br />
      2.4.6. Модифицировать и вносить любые изменения в объектный и исходный
      текст/код.
      <br />
      2.4.7.Распространять, перерабатывать в коммерческих или некоммерческих
      целях элементы Сервиса, являющиеся объектом авторских прав Лицензиара или
      третьих лиц (при наличии), при отсутствии разрешения соответствующих
      правообладателей на совершение данных действий.
      <br />‍<br />‍
      <strong>
        3. Регистрация на Сервисе
        <br />
      </strong>
      3.1.Для использования Сервиса Лицензиат регистрируется путем заполнения
      специальной формы в Сервисе.
      <br />
      3.1.1. Лицензиат указывает свое имя/наименование, а также адрес
      электронной почты и номер телефона в специальной форме в Сервисе.
      <br />
      3.1.2. Лицензиату на указанную электронную почту или номер телефона
      приходит сообщение с подтверждением регистрации.
      <br />
      3.1.3. Лицензиат подтверждает регистрацию путем перехода по ссылке в
      письме или путем ввода в Сервисе цифрового кода, направленного на номер
      телефона.
      <br />
      3.2.Подтверждение Регистрации означает полное и безоговорочное принятие
      Лицензиатом условий Соглашения (акцептом Оферты).
      <br />
      3.3. Лицензиат самостоятельно обеспечивает конфиденциальность своей
      электронной почты, номера телефона и пароля. Лицензиат не имеет права
      передавать свой пароль третьим лицам и несет полную ответственность за его
      сохранность.
      <br />
      3.4. Все действия Лицензиата в Сервисе считаются совершенными им лично или
      уполномоченным им лицом.
      <br />‍<br />
      <strong>
        4. Тестовый доступ
        <br />
      </strong>
      4.1.После прохождения регистрации Лицензиат получает право использовать
      Сервис в режиме тестового доступа.
      <br />
      4.2. В рамках тестового доступа Лицензиат вправе изучить функционал
      Сервиса в предоставленных пределах.
      <br />‍<br />
      <strong>
        5. Финансовые условия
        <br />
      </strong>
      5.1.Для получения платного доступа к Сервису Лицензиат выплачивает
      Лицензиару вознаграждение.
      <br />
      5.2. Размер лицензионного вознаграждения определяется в соответствии с
      Тарифами, размещенными на{" "}
      <a href="https://www.wecheck.ru/">https://www.wecheck.ru/</a>.<br />
      5.3. Лицензионное вознаграждение не облагается НДС в связи с применением
      Лицензиаром упрощенной системы налогообложения, не включает возможные
      комиссии провайдеров платежей. Расходы по перечислению денежных средств и
      услуги банков-корреспондентов оплачиваются Лицензиатом самостоятельно. В
      случае утраты Лицензиаром права на освобождение от исполнения обязанностей
      налогоплательщика по уплате НДС, лицензионное вознаграждение
      рассматривается как не включающее в себя НДС.
      <br />
      5.4. Обязательства по оплате считаются исполненными в момент поступления
      денежных средств на расчетный счет Лицензиара.
      <br />
      5.5. Уплаченное в соответствии с Тарифом вознаграждение возврату не
      подлежит.
      <br />‍<br />
      <strong>
        6. Порядок оплаты
        <br />
      </strong>
      6.1.Сервис позволяет внести произвольную сумму, не связанную с выбором
      Тарифа.Лицензиат вправе внести произвольную сумму при условии
      предварительного согласования с Лицензиаром.
      <br />
      6.2. Лицензиар вправе в одностороннем порядке изменять Тарифы, при этом
      размер вознаграждения и условия уже оплаченного Тарифа остаются
      неизменными.
      <br />
      6.3. Лицензиат вправе изменить Тариф только на другой Тариф с большим
      размером лицензионного вознаграждения.
      <br />
      6.4. Если Лицензиат требует изменить Тариф до истечения срока его
      действия, то Лицензиар направляет Лицензиату счет на оплату нового Тарифа
      в течение 6(шести) рабочих дней с момента получения требования. При этом
      Лицензиар осуществляет перерасчет размера вознаграждения в зависимости от
      количества дней, оставшихся до истечения срока действия старого Тарифа.
      <br />‍<br />
      <strong>
        7. Платный доступ
        <br />
      </strong>
      7.1.В течение 3 (трех) рабочих дней с момента оплаты вознаграждения по
      Тарифу Лицензиар предоставляет Лицензиату платный доступ к Сервису
      <br />
      7.2. Лицензиар направляет Лицензиату на электронную почту, указанную при
      регистрации акт предоставления прав (далее – Акт) или чек об оплате (для
      физ.лиц). Акт может размещаться в личном кабинете Лицензиата, что
      равносильно отправке Акта на электронную почту.
      <br />
      7.2.1 Лицензиат самостоятельно проверяет личный кабинет, на предмет
      отображения Акта.
      <br />
      7.3. В течение 5 (пяти) рабочих дней с момента получения Акта по
      электронной почте/отображения Акта в личном кабинете, Лицензиат:
      <br />
      7.3.1. подписывает Акт или
      <br />
      7.3.2. направляет мотивированный отказ.
      <br />
      7.4. Если по истечении 5 (пяти) рабочих дней Лицензиар не получит
      подписанный Акт или мотивированный отказ, обязательства Лицензиара
      считаются исполненными надлежащим образом и принятыми в полном объеме.
      <br />
      7.5. В рамках платного доступа Лицензиат вправе использовать функционал
      Сервисав соответствии с выбранным Тарифом.
      <br />
      7.6. Обязательства Лицензиара считаются исполненными в полном объеме с
      момента предоставления платного доступа к Сервису.
      <br />
      7.7. По окончании оплаченного срока платного доступа Исполнитель вправе
      внести вознаграждение в соответствии с Тарифами для дальнейшего
      использования Сервиса.
      <br />‍<br />
      <strong>8. Персональные данные</strong>
      <br />
      8.1.Лицензиар обрабатывает персональные данные Лицензиата в целях
      исполнения Договора в соответствии с требованиями, установленными
      Федеральным законом &quot;О персональных данных&quot; от 27.07.2006 N
      152-ФЗ.
      <br />
      8.2. Лицензиат дает согласие на обработку персональных данных в
      соответствии с Политикой конфиденциальности Лицензиара, размещенной по
      адресу: <a href={licenceLink}>https://www.wecheck.ru/licence</a>.
      <br />‍<br />
      <strong>9. Ответственность Сторон</strong>
      <br />
      9.1.Если Лицензиат без согласия Лицензиара распространит информацию,
      полученную в Сервисе, то Лицензиар вправе заблокировать Личный кабинет
      Лицензиата и потребовать выплатить штраф в размере 300 000 (трехсот тысяч)
      рублей за каждый такой случай.
      <br />
      9.2. Если Лицензиат использует Сервис с более чем 3 (трех) IP-адресов или
      более чем с 2 (двух) IP-адресов одновременно, то Лицензиар вправе
      прекратить доступ Лицензиата к Сервису.
      <br />
      9.3. Если Лицензиат без письменного согласия/поручения Лицензиара
      скопировал, декомпилировал, дизассемблировал Сервис или совершил любые
      другие попытки получения доступа к исходному коду, каким-либо иным
      способом изменил Сервис, Лицензиар вправе потребовать от Лицензиата
      выплатить штраф в размере 1 000 000(одного миллиона) рублей за каждый
      случай такого нарушения.
      <br />
      9.4. Лицензиат выплачивает штрафы в течение 5 (пяти) рабочих дней с
      момента получения требования от Лицензиара.
      <br />
      9.5. Лицензиар ни при каких условиях не возвращает уплаченное Лицензиатом
      вознаграждение.
      <br />
      9.6. Помимо установленных настоящим разделом Оферты санкций за ее
      нарушение Лицензиар вправе требовать возмещения причиненных
      соответствующим нарушением убытков, которые должны быть оплачены
      Лицензиатом в течение 5 (пяти) рабочих дней с момента получения последним
      требования от Лицензиара.
      <br />‍<br />
      <strong>10. Ограничение ответственности</strong>
      <br />
      10.1.Сервис и весь его функционал предоставляется «как есть». Лицензиар не
      гарантирует результаты, которые могут быть получены Лицензиатом в
      результате использования Сервиса, а также то, что работа Сервиса будет
      соответствовать требованиям и ожиданиям Лицензиата. Лицензиар не
      гарантирует полную и бесперебойную работоспособность, производительность и
      результаты, которые Лицензиат может получить при использовании Сервиса,
      обновлений и/или новых версий Сервиса. Лицензиар не гарантирует, что
      Сервис не содержит ошибок, а также что Сервис будет нормально
      функционировать с программным обеспечением и оборудованием Лицензиата.
      Лицензиат осознает и принимает риски, связанные с использованием Сервиса
      <br />
      10.2. При использовании Сервиса Лицензиат получает доступ к информации и
      может использовать ее на свой страх и риск, и самостоятельно несет
      ответственность за возможные последствия ее использования. Лицензиар не
      несет ответственности за информацию, содержащуюся в результатах работы
      Сервиса.
      <br />
      10.3. Лицензиар не несет ответственности:
      <br />
      10.3.1. за специализированные ресурсы и качество каналов сетей связи
      общего пользования, посредством которых предоставляется доступ к Сервису,
      а также вызванные перебоями в предоставлении услуг подрядчиков и(или)
      центров обработки и хранения данных;
      <br />
      10.3.2. за возможные сбои и перерывы в работе Сервиса, прекращение его
      функционирования и вызванную ими потерю любой информации;
      <br />
      10.3.3. за любые виды убытков, произошедшие вследствие использования
      Лицензиатом Сервиса или отдельных частей/функций Сервиса;
      <br />
      10.3.4. перед Лицензиатом или перед любыми третьими лицами за потерянные
      данные, размещенные на Сервисе;
      <br />
      10.3.5. за поломки или другие неполадки компьютера, мобильного устройства,
      любого другого устройства Лицензиата, возникшие во время использования
      Сервиса;
      <br />
      10.3.6. за последствия, вызванные утерей или разглашением Лицензиатом
      своих данных, необходимых для доступа к Сервису;
      <br />
      10.3.7. за ошибки и/или нарушения, связанные с эксплуатацией Сервиса и
      возникшие в результате неправомерных действий Лицензиата либо третьих лиц;
      <br />
      10.3.8. за действия третьих лиц по принудительному снижению доступности
      сети Интернет или программно-аппаратных компонентов Сервиса;
      <br />
      10.3.9. за сбои и перерывы в работе Сервиса, вызванные действиями
      государственных органов, включая органы правопорядка, связанных с
      наложением ареста и/или изъятием, или иным препятствованием к доступу к
      серверам подрядчиков Лицензиара, на которых размещен Сервис.
      <br />
      10.4. Размер ответственности Лицензиара по договору ограничен 10 000
      (десятью тысячами) рублей. Любая ответственность Лицензиара по договору в
      отношении компенсации убытков ограничена объемом реального ущерба,
      доказанного Лицензиатом документально, и не может превышать сумму,
      установленную настоящим пунктом Оферты.
      <br />‍<br />
      <strong>11. Разрешение споров</strong>
      <br />
      11.1.Стороны разрешают возникшие споры путем переговоров в соответствии с
      законодательством Российской Федерации.
      <br />
      11.2. Стороны обязуются соблюдать претензионный порядок разрешения споров.
      Срок для ответа на претензию составляет 20 (двадцать) рабочих дней с
      момента ее получения.
      <br />
      11.3. При невозможности разрешения спора путем переговоров и в
      претензионном порядке, спор передается на рассмотрение в суд по месту
      нахождения Лицензиара.
      <br />‍<br />
      <strong>
        12. Заключительные положения
        <br />
      </strong>
      12.1.Лицензиар вправе упоминать в своих информационных и рекламных
      материалах, публикациях в сети Интернет и презентациях, в том числе после
      прекращения действия договора: наименование Лицензиата; логотип/товарный
      знак Лицензиата; факт сотрудничества.
      <br />
      12.2 Лицензиат связывается с Лицензиаром посредством электронной почты,
      мессенджеров и чата в Сервисе. Лицензиат признает указанные средства связи
      надлежащим способом получения Лицензиатом юридически значимой информации
      подоговору, включая (но не ограничиваясь) любые сообщения, уведомления,
      требования, претензии. Соответствующая информация считается доставленной
      иполученной Лицензиатом в дату ее отправки Лицензиаром.
      <br />
      12.2. Оферта может быть изменена или дополнена Лицензиаром в любое время и
      без уведомления Лицензиата. Новая редакция Оферты вступает в силу с
      момента ее размещения на Сервисе.
      <br />
      12.3. Лицензиат самостоятельно проверяет условия Оферты на предмет их
      изменения.
      <br />
      12.4. Продолжение использования Сервиса после внесения изменений в Оферту
      означает принятие и согласие Лицензиата с такими изменениями.
      <br />
      12.5. Признание по тем или иным причинам одного или нескольких положений
      Оферты недействительными или не имеющими юридической силы, не влияет на
      действительность или применимость остальных положений Оферты.
      <br />
      <br />
      Реквизиты Лицензиара:
      <br />
      ОБЩЕСТВО С ОГРАНИЧЕННОЙ ОТВЕТСТВЕННОСТЬЮ &quot;ВИЧЕК&quot;
      <br />
      ИНН 9718180317
      <br />
      КПП 770401001
      <br />
      ОГРН 1217700491821
      <br />
      Расчётный счёт 40702810210001668842
      <br />
      БИК 044525974
      <br />
      Название банка АО «ТБанк»
      <br />
      Корр. счёт 30101810145250000974
      <br />
      Юридический адрес 119021,г. Москва, вн.тер.г. муниципальный округ
      Хамовники, б-р Зубовский, д. 21, стр.1, помещ. 1/3
      <br />
      Электронная почта: <a href="mailto:hello@wecheck.ru">info@wecheck.ru</a>
      <br />
      Оферта размещена в сети Интернет по адресу:{" "}
      <a href={agreementLink}>https://www.wecheck.ru/agreement</a>
    </div>
  );
};

export default Agreement;
