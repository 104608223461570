const AimIcon = (props) => {
    return (
        <svg {...props} viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_7964_95215)">
                <circle cx="11" cy="14" r="10" stroke="#6B7585" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                <circle cx="11" cy="14" r="5" stroke="#6B7585" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M11 14L20.5 4.5M20.5 4.5V1M20.5 4.5H24" stroke="#6B7585" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            </g>
            <defs>
                <clipPath id="clip0_7964_95215">
                    <rect width="25" height="25" fill="white"/>
                </clipPath>
            </defs>
        </svg>

    )
}

export default AimIcon;