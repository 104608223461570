const CalendarIcon = () => {
    return (
        <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="Frame 92322" clipPath="url(#clip0_8980_28822)">
                <path id="Rectangle 1876" d="M0.75 11.5C0.75 10.0919 0.750406 9.07341 0.805351 8.26812C0.859836 7.46957 0.965434 6.93561 1.14963 6.49091C1.68248 5.20451 2.70451 4.18248 3.99091 3.64963C4.43561 3.46543 4.96957 3.35984 5.76812 3.30535C6.57341 3.25041 7.59191 3.25 9 3.25H11C12.4081 3.25 13.4266 3.25041 14.2319 3.30535C15.0304 3.35984 15.5644 3.46543 16.0091 3.64963C17.2955 4.18248 18.3175 5.20451 18.8504 6.49091C19.0346 6.93561 19.1402 7.46957 19.1946 8.26812C19.2496 9.07341 19.25 10.0919 19.25 11.5C19.25 12.9081 19.2496 13.9266 19.1946 14.7319C19.1402 15.5304 19.0346 16.0644 18.8504 16.5091C18.3175 17.7955 17.2955 18.8175 16.0091 19.3504C15.5644 19.5346 15.0304 19.6402 14.2319 19.6946C13.4266 19.7496 12.4081 19.75 11 19.75H9C7.59191 19.75 6.57341 19.7496 5.76812 19.6946C4.96957 19.6402 4.43561 19.5346 3.99091 19.3504C2.70451 18.8175 1.68248 17.7955 1.14963 16.5091C0.965434 16.0644 0.859836 15.5304 0.805351 14.7319C0.750406 13.9266 0.75 12.9081 0.75 11.5Z" stroke="#00745F" strokeWidth="1.5"/>
                <path id="Vector 52" d="M15.4468 9.24902L4.55328 9.24902" stroke="#00745F" strokeWidth="1.6" strokeLinecap="round"/>
                <path id="Union" fillRule="evenodd" clipRule="evenodd" d="M5.99922 5.96517C6.44105 5.96517 6.79922 5.607 6.79922 5.16517L6.79922 1.80195C6.79922 1.36013 6.44105 1.00195 5.99922 1.00195C5.55739 1.00195 5.19922 1.36013 5.19922 1.80195L5.19922 5.16517C5.19922 5.607 5.55739 5.96517 5.99922 5.96517Z" fill="#00745F"/>
                <path id="Union_2" fillRule="evenodd" clipRule="evenodd" d="M13.9992 5.96517C14.441 5.96517 14.7992 5.607 14.7992 5.16517L14.7992 1.80195C14.7992 1.36013 14.441 1.00195 13.9992 1.00195C13.5574 1.00195 13.1992 1.36013 13.1992 1.80195L13.1992 5.16517C13.1992 5.607 13.5574 5.96517 13.9992 5.96517Z" fill="#00745F"/>
            </g>
            <defs>
                <clipPath id="clip0_8980_28822">
                    <rect width="20" height="20" fill="white" transform="translate(0 0.5)"/>
                </clipPath>
            </defs>
        </svg>
    )
}

export { CalendarIcon };