import styled from "styled-components";

export const StyledModal = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 130.3%;
  color: #333d37;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 999;
  margin: 16px;
  max-width: 260px;
  ${(props) => (props.isOpen ? "display: flex;" : "display: none;")}
`;
export const StyledModalContent = styled.div`
  padding: 24px;
  background: #ffffff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  img {
    width: 32px;
    margin-bottom: 12px;
  }
  p {
    span {
      text-decoration: underline;
    }
  }
  p:last-child {
    margin-top: 10px;
    color: #00745F;
    cursor: pointer;
    &:hover {
      color: #005641;
    }
  }
`;
