import { useState } from "react";
import { Container } from "../elements";
import Checkbox from "../components/Checkbox/Checkbox";
import { Input } from "../components/Input/Input";
import {
  Title,
  TitleText,
  Text,
  StockForm,
  StockContainer,
  LeftSideContent, RightSideContent, StockFormButton, FirstRound, SuccessImage, ErrorText
} from "../components/StockPage/StockPageStyles";
import Success from "../assets/img/success.png";
import { apiUrl } from "../utils";
import { useLinks } from "../hooks"
import {useParams} from "react-router-dom";

const inputs = {
  name: {
    placeholder: 'Представьтесь',
    type: 'text',
    initialValue: ''
  },
  company: {
    placeholder: 'Ваша компания',
    type: 'text',
    initialValue: ''
  },
  mail: {
    placeholder: 'E-mail',
    type: 'email',
    initialValue: '',
    required: true,
    errorText: 'Укажите ваш email'
  },
  phone: {
    placeholder: 'Телефон',
    type: 'phone',
    initialValue: '',
    required: true,
    errorText: 'Укажите ваш номер телефона'
  },
  policy: {
    errorText: 'Пожалуйста, подтвердите согласие',
    hide: true,
  }
};

const setInitialForm = () => {
  return Object.keys(inputs).reduce((acc, input) => {
    acc[input] = inputs[input].initialValue;

    return acc;
  }, {})
}

const Stock = () => {
  const [form, setForm] = useState(setInitialForm());
  const [policy, setPolicy] = useState(true);
  const [loading, setLoading] = useState(false);
  const [isSend, setIsSend] = useState(null);
  const [hideFrom, setHideForm] = useState(false);
  const [formErrors, setFormErrors] = useState([]);

  const { company, mail, name, phone } = form;
  const { licenceLink } = useLinks();

  const { id } = useParams();

  const onChangeInput = (e, name) => {
    let value = e.target.value;
    if (name === 'phone') {
      value = e.target.value.replace(/\D/g, "");
    }
    setForm({
      ...form,
      [name]: value
    })
  };

  const onSubmit = async () => {
    let errors = [];
    if (!policy) {
      errors.push('policy');
    }
    if (!phone.length) {
      errors.push('phone');
    }
    if (!mail.length) {
      errors.push('mail');
    }
    if (errors.length) {
      setFormErrors(errors);
      return false
    } else {
      setFormErrors([]);
    }

    await setLoading(true);
    const response = await fetch(`${apiUrl}/saveform?name=${id ? `${id} - ${name}` : name}&email=${mail}&phone_number=${phone}&comment=${company}&type=ecomexpo`, {
      method: 'POST',
    });
    await setLoading(false);
    if (response.status === 200) return setIsSend(true);
    if (response.status !== 200) return setIsSend(false);
  };

  return (
    <Container>
      <StockContainer>
        <FirstRound />
        <LeftSideContent>
          <Title>
            30% скидка <br/> участникам выставки
          </Title>
          <TitleText>
            Заполни форму с 5 по 6 июня,
            получи гарантированную скидку 30%
            при оплате любого тарифа
          </TitleText>
        </LeftSideContent>
        <RightSideContent>
          {!hideFrom && (
            <StockForm error={formErrors.length}>
              {isSend !== true && (
                <>
                  {Object.keys(inputs).map(input => {
                    if (inputs[input].hide) return null;
                    return (
                      <Input
                        type={inputs[input].type}
                        name={input}
                        value={form[input]}
                        placeholder={inputs[input].placeholder + (inputs[input].required ? '*' : '')}
                        onChange={(e) => onChangeInput(e, input)}
                        key={input}
                        pattern={inputs[input].pattern}
                      />
                    )
                  })}
                  <Checkbox
                    checked={policy}
                    onChange={() => setPolicy((prev) => !prev)}
                    label={[
                      "Принимаю ",
                      <a
                        target="blank"
                        onClick={(e) => e.stopPropagation()}
                        key="link"
                        href={licenceLink}
                      >
                        политику конфиденциальности
                      </a>
                    ]}
                  />
                  {formErrors.length > 0 && formErrors.map(error => (
                    <ErrorText key={error}>
                      {inputs[error].errorText}
                    </ErrorText>
                  ))}
                  <StockFormButton disabled={loading} onClick={() => onSubmit()}>
                    <span>
                      Отправить
                    </span>
                  </StockFormButton>
                </>
              )}
              {isSend === true && (
                <>
                  <SuccessImage src={Success} alt="no-image" />
                  <TitleText>
                    В течение 24 часов мы пришлем вам промокод<br/>
                    При оплате укажите промокод и мы активируем 30% скидку
                  </TitleText>
                  <Text>
                    Промокод действует до 30 июня!
                  </Text>
                  <StockFormButton disabled={loading} onClick={() => setHideForm(true)}>
                    <span>
                      Хорошо
                    </span>
                  </StockFormButton>
                </>
              )}
            </StockForm>
          )}
        </RightSideContent>
      </StockContainer>

    </Container>
  )
}

export default Stock;
