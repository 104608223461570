import React, { useState } from "react";
import {
    StyledButtonAdditionText,
} from "./HeadSectionStyle";
import {ImgPopup, PrimaryButton} from "../../elements";
import FireIcon from "../../assets/img/fire3.png";
import ym from "react-yandex-metrika";
import gtag from "ga-gtag";
import NoCardIcon from "../../assets/icons/NoCardIcon";
import AnalyticIcon from "../../assets/icons/AnalyticIcon";
import ArrowRightIcon from "../../assets/icons/ArrowRightIcon";
import SwitcherIcon from "../../assets/icons/SwitcherIcon";
import SettingsIcon from "../../assets/icons/SettingsIcon";
import AimIcon from "../../assets/icons/AimIcon";

const PlayComponent = () => {
    return (
        <svg width="21" height="22" viewBox="0 0 27 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 24.7101V3.28991C1 1.79027 2.58932 0.824092 3.92063 1.5144L24.5758 12.2245C26.015 12.9707 26.015 15.0293 24.5758 15.7755L3.92063 26.4856C2.58932 27.1759 1 26.2097 1 24.7101Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}

export const HeadSectionComponent = () => {
  const [openDemo, setOpenDemo] = useState(false);
    const [open, setOpen] = useState(false);
    const closeModal = () => setOpen(false);

  const closeModalDemo = () => {
    setOpenDemo(false);
    window.location.hash === "";
  };

    const handleClick = () => {
        setOpen(true);
    };
  return (
    <div className="relative mt-10 lg:mt-0">
        <ImgPopup
            video
            closeModal={() => closeModal()}
            open={open}
            title={'Посмотрите видео, как работает WeCheck'}
        />
        <span className="block lg:text-[82px] lg:leading-[82px] text-[32px] leading-[36px] text-[#313d53] font-black">
            WeCheck — увеличивает <br/>прибыль на <span className="text-[#AA0C91]">wildberries</span>{/* и{" "}
            <span className="text-[#0055EB]">ozon</span>*/}
        </span>
        <div className="mt-4 lg:mt-[50px] block lg:flex lg:space-x-10 space-y-4 lg:space-y-0">
            <div className="space-y-4">
                <div className="flex items-center">
                    <div className="w-[30px] h-[30px] border border-black/10 flex items-center justify-center rounded-lg">
                        <AnalyticIcon width="24" height="24" className="text-[#7D8694]"/>
                    </div>
                    <div className="w-full lg:w-fit flex justify-between items-center">
                    <span className="block ml-5 text-[#313D53]/70 font-medium lg:text-2xl text-base">
                        Аналитика конкурентов
                    </span>
                    <ArrowRightIcon width="14" height="15" className="text-[#6C7586] relative top-[3px] ml-1"/>
                    </div>
                </div>
                <div className="flex items-center">
                    <div className="w-[30px] h-[30px] border border-black/10 flex items-center justify-center rounded-lg">
                        <SwitcherIcon width="24" height="24" className="text-[#7D8694]"/>
                    </div>
                    <div className="w-full lg:w-fit flex justify-between items-center">
                    <span className="block ml-5 text-[#313D53]/70 font-medium lg:text-2xl text-base w-[210px] lg:w-auto">
                        Управление продажами
                    </span>
                    <ArrowRightIcon width="14" height="15" className="text-[#6C7586] relative top-[3px] ml-1"/>
                    </div>
                </div>
            </div>
            <div className="space-y-4">
                <div className="flex items-center">
                    <div className="w-[30px] h-[30px] border border-black/10 flex items-center justify-center rounded-lg">
                        <SettingsIcon width="24" height="24" className="text-[#7D8694]"/>
                    </div>
                    <div className="w-full lg:w-fit flex justify-between items-center">
                    <span className="block ml-5 text-[#313D53]/70 font-medium lg:text-2xl text-base">
                        SEO-продвижение
                    </span>
                    <ArrowRightIcon width="14" height="15" className="text-[#6C7586] relative top-[3px] ml-1"/>
                    </div>
                </div>
                <div className="flex items-center">
                    <div className="w-[30px] h-[30px] border border-black/10 flex items-center justify-center rounded-lg">
                        <AimIcon width="24" height="24" className="text-[#7D8694]"/>
                    </div>
                    <div className="w-full lg:w-fit flex justify-between  items-center">
                    <span className="block ml-5 text-[#313D53]/70 font-medium lg:text-2xl text-base">
                        Рекламные ставки
                    </span>
                    <ArrowRightIcon width="14" height="15" className="text-[#6C7586] relative top-[3px] ml-1"/>
                    </div>
                </div>
            </div>
        </div>
        <div className="block lg:flex mt-[32px] lg:mt-[60px] mb-[18px]">
            <div className="flex flex-col justify-center">
                <PrimaryButton
                  onClick={() => setOpenDemo(true)}
                  open={openDemo}
                  closeModal={() => closeModalDemo()}
                  subText={
                    <span className="flex text-gray-600/60 items-center text-sm">
                        <NoCardIcon width="19" height="17" className="relative top-[1px]"/>
                        <span className="block ml-2">
                            Без привязки карты
                        </span>
                    </span>
                  }
                  icon={FireIcon}
                  tag={() => {
                    gtag("event", "form_send", {
                      form: "dostup_head",
                    });
                    gtag("event", "form_send", {
                      form: "24_hour",
                    });
                    gtag("event", "form_send", {
                      form: "all_leads",
                    });
                  }}
                  tag2={() => {
                    ym("reachGoal", "form_dostup_head");
                    ym("reachGoal", "24_hour");
                    ym("reachGoal", "all_leads");
                  }}
                >
                  Попробовать бесплатно
                </PrimaryButton>
            </div>
            <div className="hidden lg:block mx-[34px] w-px h-[60px] bg-[#22478B]/10"/>
            <div className="flex">
                <div className="w-full lg:w-auto flex items-center h-fit mt-8 lg:mt-0 border border-[#FFFFFF] z-20 border-2 p-4 lg:p-0 lg:border-none rounded-xl">
                    <button onClick={handleClick}
                        className="w-[60px] h-[60px] flex items-center justify-center border-2 border-[#00745F] rounded-full bg-[#00745F] hover:bg-[#005641]">
                        <PlayComponent />
                    </button>
                    <StyledButtonAdditionText>
                        <p>Смотреть видео</p>
                        <p>о сервисе</p>
                    </StyledButtonAdditionText>
                </div>
            </div>
        </div>
    </div>
  );
};
