import { useEffect } from "react";

/**
* Функция, которая будет ждать определенный промежуторк времени, и после запускать
* переданный callback
*
* @param {{
*   callback: Function,
*   timeout: number,
*   deps?: unknown[]
* }} props
*
* @param props.callback Функция, которая будет вызвана после периода ожидания
* @param props.timeout Значение, которое будет обозначать период ожидания перед выполнением
* @param props.deps Массив значений, за которыми необхдимо наблюдать react'у с целью перерисовок
* */
export function useDebounce({ callback, timeout, deps = [] }) {
  useEffect(
    () => {
      const handler = setTimeout(() => {
        callback()
      }, timeout);

      return () => {
        clearTimeout(handler);
      };
    },
    [callback, timeout, ...deps] // Only re-call effect if value or delay changes
  );
}
