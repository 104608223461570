import React from "react";
import CloseModal from "../../assets/img/close-modal.png";
import {
  StyledPopup,
  StyledModal,
  StyledModalHeader,
  StyledModalContent,
  StyledPopupButton,
} from "./EmailPopupStyle";
/**
* Компонент, который используется для завершающей работы с eMail пользователя
* (перейдите на почту для подтверждения, ...)
*
* @param {{
*   closeModal(): void,
*   open: boolean,
*   title: string,
*   bodyText?: string,
*   submitText?: string,
* }} props
*
* @param props.closeModal Функция, которая будет вызвана при нажатии на кнопку закрытия модального окна
* @param props.open Флаг, определяющий видимость элемента
* @param props.title Заголовок компонента
* @param props.bodyText Опциональный текст, если передан - будет отрисован внутри  модального окна
* @param props.submitText Опциональный текст, который будет отрисован внутри кнопки подтверждения
* */
export const EmailPopup = ({
  closeModal,
  open,
  title,
  bodyText = "Ваша заявка принята, в ближайшее время мы свяжемся с вами",
  submitText = "Отлично, спасибо!"
}) => {
  return (
    <StyledPopup
      modal
      lockScroll
      open={open}
      closeOnDocumentClick
      onClose={closeModal}
    >
      <StyledModal>
        <StyledModalHeader>
          <p>{title}</p>
          <img onClick={() => closeModal()} src={CloseModal} alt="no img" />
        </StyledModalHeader>
        <StyledModalContent>
          <p>{bodyText}</p>
          <StyledPopupButton onClick={closeModal}>
            {submitText}
          </StyledPopupButton>
        </StyledModalContent>
      </StyledModal>
    </StyledPopup>
  );
};
