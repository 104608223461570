export const TopBannerIcon = () => {
  return (
    <svg width="32" height="16" viewBox="0 0 94 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M70.9581 31.7439C72.3028 28.8752 73.6027 26.365 74.8577 24.2135C76.2024 22.0619 77.5023 20.269 78.7574 18.8346H0.36048V13.1868H78.7574C77.5023 11.6628 76.2024 9.82503 74.8577 7.67348C73.6027 5.52194 72.3028 3.05662 70.9581 0.277545H75.6646C81.3124 6.82183 87.2291 11.6628 93.4148 14.8005V17.221C87.2291 20.269 81.3124 25.11 75.6646 31.7439H70.9581Z"
        fill="black"
      />
    </svg>
  );
};
