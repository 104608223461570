import gtag from "ga-gtag";
import React, { useState } from "react";
import ym from "react-yandex-metrika";
import { ReactComponent as ChoseFor1 } from "../../assets/svg/chosefor1.svg";
import { ReactComponent as ChoseFor2 } from "../../assets/svg/chosefor2.svg";
import { ReactComponent as ChoseFor3 } from "../../assets/svg/chosefor3.svg";
import { Container, RequestPopup } from "../../elements";
import { AnalyticCardComponent } from "../AnalyticCard/AnalyticCardComponent";
import {
  StyledAlert,
  StyledAlertButton,
  StyledAlertContainer,
  StyledAlertText,
  StyledContent,
  StyledHoverContainer,
  StyledSection,
  StyledTitle,
} from "./ChoseForSectionStyle";

export const ChoseForSection = () => {
  const [open, setOpen] = useState(false);
  const closeModal = () => {
    setOpen(false);
    window.location.hash = "";
  };

  return (
    <StyledSection id="wecheck_section">
      <Container className="lg:!px-0">
        <StyledTitle>WeCheck выбирают за</StyledTitle>
        <StyledContent>
          <AnalyticCardComponent
            popup={false}
            height={"auto"}
            Icon={ChoseFor1}
            title="Точные данные"
            subText="Наши алгоритмы гарантируют точные данные. Принимайте решение на основании проверенных цифр."
          />
          <AnalyticCardComponent
            popup={false}
            height={"auto"}
            Icon={ChoseFor2}
            title="Удобный интерфейс"
            subText="Мы создали самую удобную платформу для аналитики маркетплейсов в России. Попробуйте сами."
          />
          <AnalyticCardComponent
            popup={false}
            height={"auto"}
            Icon={ChoseFor3}
            title="Быструю поддержку"
            subText="Мы всегда на связи. Наши эксперты ответят на любые вопросы о платформе WeCheck."
          />
        </StyledContent>
        <RequestPopup
          type="demo_access"
          title={"Запрос доступа на 3 дня"}
          closeModal={closeModal}
          open={open}
          tag={() => {
            gtag("event", "form_send", {
              form: "warning_demo_form",
            });
            gtag("event", "form_send", {
              form: "demo_form_all",
            });
            gtag("event", "form_send", { form: "all_leads" });
          }}
          tag2={() => {
            ym("reachGoal", "warning_demo_form");
            ym("reachGoal", "demo_form_all");
            ym("reachGoal", "all_leads");
          }}
        />
        <StyledAlertContainer onClick={() => setOpen(true)}>
          <StyledAlert>Внимание</StyledAlert>
          <StyledAlertText>
            Воспользуйтесь демо-доступом.
            <span> Это бесплатно и дает понимание полезности сервиса.</span>
          </StyledAlertText>
          <StyledAlertButton>Получить демо-доступ ›</StyledAlertButton>
          <StyledHoverContainer>
            <p>Получить демо-доступ ›</p>
          </StyledHoverContainer>
        </StyledAlertContainer>
      </Container>
    </StyledSection>
  );
};
