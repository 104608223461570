import {StyledDetailItem, StyledDetailItemName, StyledDevider} from "./RateItemStyle";
import {ReactComponent as Check} from "../../assets/svg/check.svg";
import {ReactComponent as Cross} from "../../assets/svg/cross.svg";
import React from "react";
import ArrowRightIcon from "../../assets/icons/ArrowRightIcon";
import cn from "classnames";

const DetailInfo = ({detailInfo, isExpanded: expanded, expandedKeys, setExpandedKeys }) => {
    const { label, expandable, details } = detailInfo;
    const handleExpand = () => {
        if (expanded) {
            const newKeys = [...expandedKeys];
            const index = newKeys.indexOf(label);
            newKeys.splice(index, 1);
            setExpandedKeys(newKeys)
        } else {
            const newKeys = [...expandedKeys];
            newKeys.push(label)
            setExpandedKeys(newKeys)
        }
    }
    return (
        <div className="border border-black/12 rounded-lg p-6 lg:min-w-[380px] min-w-[300px]">
            <div onClick={() => {
                if (expandable && details.filter(el => el.offered).length !== 0) {
                    handleExpand()
                }
            }} className="cursor-pointer">
                <div className="flex items-center justify-between">
                    <span className={`block ${expandable && expanded ? 'text-[#00745F]' : 'text-[#333D37]' } font-bold text-lg leading-[24px]`}>
                        {label}
                    </span>
                    {expandable && details.filter(el => el.offered).length !== 0 && <ArrowRightIcon width="24" height="24" className={!expanded ? 'rotate-90 text-[#6E6E6E]/60' : '-rotate-90 text-[#00745F]'} />}
                </div>
                {expandable &&
                    <div className="flex space-x-2 mt-1.5">
                        <span className="block text-[#6E6E6E] text-xs leading-[13px] relative top-[7px]">{'Доступно инструментов'.toUpperCase()}</span>
                        <span className={cn("block text-[#00745F] text-base font-medium relative", {'!text-[#A26D22]': details.filter(el => el.offered).length === 0})}>{`${details.filter(el => el.offered).length} из ${details.length}`}</span>
                    </div>
                }
            </div>
            {expanded && details.filter(el => el.offered).length !== 0 &&
                <div className="mt-6">
                    {details.map((item, index) => {
                        return item.name === "devider" ? (
                            <StyledDevider key={index}/>
                        ) : (
                            <StyledDetailItem key={index}>
                                <StyledDetailItemName offered={item.offered}>
                                    <p>{item.name}</p>
                                </StyledDetailItemName>
                                {item.offered ? <Check/> : <Cross/>}
                            </StyledDetailItem>
                        );
                    })}
                </div>
            }
        </div>
    )
}

export default DetailInfo;