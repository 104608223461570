import React from "react";
import Mail from "../../assets/img/mail.png";
import { Container } from "../../elements";
import {
  StyledBorder,
  StyledCentrBlock,
  StyledContent,
  // StyledRightBlock,
  StyledCopyright,
  StyledEmail,
  StyledFooter,
  StyledLeftBlock,
} from "./FooterStyle";
/*import TgIcon from "../../assets/img/tg-icon.png";
import VkIcon from "../../assets/img/vk-icon.png";
import ym from "react-yandex-metrika";
import gtag from "ga-gtag";*/
import styled from "styled-components";
import { useLinks } from "../../hooks";

// const Devider = styled.div`
//   width: 100%;
//   height: 1px;
//   background: rgba(255, 255, 255, 0.1);
//   border-radius: 1px;
// `

const InfoSection = styled.p`
  cursor: auto !important;
  text-decoration: none !important;
`;

const TelephoneContainer = styled.div`
  padding: 0.75rem 0;
  text-align: left;
  display: grid;
  // font-family: 'Sofia Pro';
  font-weight: 400;
  letter-spacing: 0;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
`;

const TelephoneTitle = styled.a`
  color: rgba(255, 255, 255, 1);
  font-size: 1.2rem;
  text-decoration: none;
  transition: all ease 0.15s;
  width: fit-content;

  ${TelephoneContainer}:hover & {
    color: #00745f;
  }
`;

const TelephoneSubtitle = styled.p`
  font-size: 0.8rem;
  line-height: 1.2rem;
  opacity: 0.7;
`;

const TelephoneTime = styled.strong`
  transition: all ease 0.15s;
  font-weight: 400;

  ${TelephoneContainer}:hover & {
    color: #00745f;
    font-weight: 600;
  }
`;

export const Footer = () => {
  const {
    marketplaceLink,
    // parsingLink,
    blogLink,
    // competitorsLink,
    licenceLink,
    // vkLink,
  } = useLinks();
  /*const handleClickVk = (e) => {
    e.preventDefault();
    gtag("event", "click_button", {
      button: "vk",
    });
    ym("reachGoal", "vk");
    window.open(vkLink);
  };

  const handleClickTelegram = (e) => {
    gtag("event", "click_button", {
      button: "tg2",
    });
    ym("reachGoal", "tg2");
    e.preventDefault();
    window.open("https://t.me/wecheckru");
  };*/

  const telephone = "+7 (495) 161 51 55";
  const telephoneLink = `tel:${telephone.replace(/\s|\(|\)/g, "")}`;

  return (
    <StyledFooter>
      <Container className="lg:!px-0">
        <StyledContent>
          <StyledEmail style={{ display: "flex" }}>
            <div>
              <img src={Mail} alt="no img" />
            </div>
            <StyledLeftBlock>
              <p>Свяжитесь с нами</p>
              <p>
                Если у вас остались вопросы или хотите обсудить сотрудничество,
                пишите:
              </p>
              <TelephoneContainer>
                <TelephoneTitle href={telephoneLink}>
                  {telephone}
                </TelephoneTitle>
                <TelephoneSubtitle>
                  Звоните в будни с <TelephoneTime>10:00 — 19:00</TelephoneTime>
                </TelephoneSubtitle>
              </TelephoneContainer>
              <p style={{ textDecoration: "underline" }}>info@wecheck.ru</p>
              <p style={{ textDecoration: "underline" }}>wecheck.ru</p>
            </StyledLeftBlock>
          </StyledEmail>
          <StyledCentrBlock>
            <a href={marketplaceLink}>Маркетплейсы</a>
            <a
              href={
                "https://chromewebstore.google.com/detail/wecheck/nbhkkkflcfheikkjmcphbfhbleblccgl"
              }
              target="_blank"
              rel="noreferrer"
            >
              Плагин для браузера
            </a>
            {/*<a href={parsingLink}>Парсинг</a>*/}
            {/*<a href={competitorsLink}>Конкуренты</a>*/}
            <a href={blogLink}>Блог</a>
          </StyledCentrBlock>
          {/* <StyledRightBlock>
            <div className="flex">
              <a onClick={(e) => handleClickTelegram(e)} href="">
                <img src={TgIcon} alt="no img" />
              </a>
               <a href="">
                <img src={FbIcon} alt="no img" />
              </a>
              <a onClick={(e) => handleClickVk(e)} href="">
                <img src={VkIcon} alt="no img" />
              </a>
            </div>
            <p>Следите за нами в соц сетях</p>
          </StyledRightBlock>*/}
        </StyledContent>
      </Container>
      <StyledBorder></StyledBorder>
      <Container>
        <StyledCopyright>
          <p>{`© 2019-${new Date().getFullYear()} WeCheck. Все права защищены`}</p>
          <InfoSection>ООО “ВИЧЕК”</InfoSection>
          <InfoSection>ОГРН: 1217700491821</InfoSection>
          <InfoSection>ИНН: 9718180317</InfoSection>

          <p onClick={() => window.open("/agreement")}>Договор оферта</p>
          <p onClick={() => window.open(licenceLink)}>
            Политика конфиденциальности
          </p>
        </StyledCopyright>
      </Container>
    </StyledFooter>
  );
};
