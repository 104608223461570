const NoCardIcon = (props) => {
    return (
        <svg {...props} viewBox="0 0 17 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M14.0581 0.108271C14.2745 0.279864 14.3108 0.59436 14.1392 0.810717L4.24333 18.3315C4.07174 18.5479 3.75724 18.5842 3.54088 18.4126C3.32453 18.241 3.28824 17.9265 3.45983 17.7102L13.3557 0.189322C13.5273 -0.0270345 13.8418 -0.0633225 14.0581 0.108271Z" fill="currentColor" fillOpacity="0.6"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M12.4119 3.625H3.25C2.6872 3.625 2.16783 3.81097 1.75 4.12482C1.52717 4.29219 1.33322 4.49593 1.17696 4.72722C0.9074 5.12623 0.75 5.60723 0.75 6.125V8.25V12.3958C0.75 13.7765 1.86929 14.8958 3.25 14.8958H6.04612L6.61093 13.8958H3.25C2.42157 13.8958 1.75 13.2243 1.75 12.3958V8.25H9.79972L10.3645 7.25H1.75V6.125C1.75 5.29657 2.42157 4.625 3.25 4.625H11.8471L12.4119 3.625ZM12.499 7.25H15.25V6.125C15.25 5.37212 14.6953 4.7488 13.9723 4.64137L14.4845 3.73463C15.5068 4.04837 16.25 4.99989 16.25 6.125V8.25V12.3958C16.25 13.7765 15.1307 14.8958 13.75 14.8958H8.18059L8.74539 13.8958H13.75C14.5784 13.8958 15.25 13.2243 15.25 12.3958V8.25H11.9342L12.499 7.25Z" fill="currentColor" fillOpacity="0.6"/>
        </svg>

    )
}

export default NoCardIcon;
