import styled from "styled-components";

export const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
  outline: none;
`;

export const CheckMark = styled.svg`
  fill: none;
  stroke: white;
  stroke-width: 2px;
  margin: auto;
`;

export const StyledCheckbox = styled.div`
  display: flex;
  
  width: 24px;
  height: 24px;
  
  margin-right: 12px;
  
  background: ${props => props.checked ? '#219653' : '#FFFFFF'};

  border: 1px solid ${props => !props.checked && '#219653'};

  border-radius: 3px;
  
  transition: all 150ms;

  ${HiddenCheckbox}:focus + & {
    box-shadow: 0 0 0 3px pink;
  };

  ${CheckMark} {
    visibility: ${props => props.checked ? 'visible' : 'hidden'}
  };
`;

export const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`;

export const CheckboxLabel = styled.label`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;

  color: rgba(110, 110, 110, 0.75);
  
  a {
    color: #219653;
    text-decoration: none;
  }
`;