import React from "react";
import {
  StyledSection,
  StyledTelegramContainer,
  StyledContainer,
} from "./AnswersSectionStyle";
// import { AccordionItem } from "../../elements";
// import { data } from "./AnswerAccordionData";
import TgIcon from "../../assets/img/tg-icon.png";
import ym from "react-yandex-metrika";
import gtag from "ga-gtag";

export const AnswerSection = () => {
  const handleClickTelegram = () => {
    gtag("event", "click_button", {
      button: "tg",
    });
    ym("reachGoal", "tg");
    window.open("https://t.me/wechek_sale");
  };

  return (
    <StyledSection id="telegram_section">
      <StyledContainer className="lg:!px-0">
        {/* <StyledTitle>Часто задаваемые вопросы</StyledTitle>
        <StyledAccordionContainer>
          {data.map((item, index) => {
            return (
              <AccordionItem
                key={index}
                title={item.title}
                content={item.content}
              />
            );
          })}
        </StyledAccordionContainer> */}
        <StyledTelegramContainer onClick={handleClickTelegram}>
          <img src={TgIcon} alt="no img"></img>
          <p>
            Остались вопросы? Задайте в Telegram <span>чате ›</span>
          </p>
        </StyledTelegramContainer>
      </StyledContainer>
    </StyledSection>
  );
};
