import { ReactComponent as Telegram } from "../../assets/svg/telegram.svg";
import { ReactComponent as VK } from "../../assets/svg/vk.svg";

export const ChatLinks = [
  // {
  //   Icon: WhatsApp,
  //   link: 'https://wa.me/79522379685',
  //   tooltip: 'Написать в WhatsApp',
  //   id: 'chat_whatsapp_toggler',
  // },
  {
    Icon: Telegram,
    link: "https://t.me/we_check",
    tooltip: "Написать в Telegram",
    id: "chat_telegram_toggler",
  },
  {
    Icon: VK,
    link: "https://vk.com/im?media=&sel=-211040288",
    tooltip: "Написать в VK",
    id: "chat_vk_toggler",
  },
  /*  {
    Icon: Chat,
    tooltip: 'Написать в чат WeCheck',
    id: 'chat_wecheck_toggler',
    onClick: () => window.jivo_api.open()
  },*/
];

export const PHONE_NUMBER = "+7 (495) 161 51 55";
