import styled from "styled-components";

export const InputStyled = styled.input`
  font-family: 'Sofia Pro', sans-serif;
  padding: 12px 24px;
  
  background: #F5F5F5;
  
  border: 1px solid rgba(0, 0, 0, 0.06);
  border-radius: 8px;

  font-weight: 500;
  font-size: 16px;
  line-height: 37px;
  
  color: #333333;
  
  outline: none;
  
  &::placeholder {
    color: #6E6E6E;
  }

  @media (min-width: 768px) {
    font-weight: 400;
    font-size: 20px;
    line-height: 47px;
  }
`;