const WarningIcon = () => {
    return (
        <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_8020_73359)">
                <rect x="0.75" y="1.25" width="18.5" height="18.5" rx="5.25" stroke="#00745F" strokeWidth="1.5"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M10.8002 5.5002C10.8002 5.05837 10.442 4.7002 10.0002 4.7002C9.55837 4.7002 9.2002 5.05837 9.2002 5.5002V11.0002C9.2002 11.442 9.55837 11.8002 10.0002 11.8002C10.442 11.8002 10.8002 11.442 10.8002 11.0002V5.5002ZM10.8002 15.0002C10.8002 14.5584 10.442 14.2002 10.0002 14.2002C9.55837 14.2002 9.2002 14.5584 9.2002 15.0002V15.5002C9.2002 15.942 9.55837 16.3002 10.0002 16.3002C10.442 16.3002 10.8002 15.942 10.8002 15.5002V15.0002Z" fill="#00745F"/>
            </g>
            <defs>
                <clipPath id="clip0_8020_73359">
                    <rect width="20" height="20" fill="white" transform="translate(0 0.5)"/>
                </clipPath>
            </defs>
        </svg>

    )
}

export default WarningIcon;