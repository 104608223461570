import styled, { keyframes } from "styled-components";
/**
 * @typedef IProps
 *
 * @property {boolean} isVisible Флаг, определяющий видимость компонента
 * @property {boolean} [isWithOverlay] Флаг, определяющий видимость фона за Loader'ом
 * @property {string} [spinnerColor] Цвет spinner'а
 * @property {string} [spinnerBorderWidth] Ширина spinner'а
 * */
const loaderKeyframes = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`
const Overlay = styled.div`
  z-index: ${({ isVisible }) => isVisible ? '10000' : '-10000'};
  opacity: ${({ isVisible }) => isVisible ? 0.8 : 0};
  transition: all ease 0.15s;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  position: absolute;
  background: #fff;
`

const Container = styled.div`
  display: ${({ isVisible }) => isVisible ? 'inline-block' : 'none'};
  position: relative;
  width: 80px;
  height: 80px;

  div {
    border: ${({ spinnerBorderWidth }) => spinnerBorderWidth} solid ${({ spinnerColor }) => spinnerColor};
    border-color: ${({ spinnerColor }) => spinnerColor} transparent transparent transparent;
    animation: ${loaderKeyframes} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;

    &:nth-child(1) {
      animation-delay: -0.45s;
    }

    &:nth-child(2) {
      animation-delay: -0.3s;
    }

    &:nth-child(3) {
      animation-delay: -0.15s;
    }
  }
`
/**
 * @returns {JSX.Element} Loader в виде Spinner'а
 *
 * @param {IProps & import('react').HTMLAttributes<HTMLDivElement>} props
 * */
export default function LoaderSpinner({ isVisible, isWithOverlay, spinnerBorderWidth = "8px", spinnerColor = "#fff", ...divProps }) {
  if (isWithOverlay) {
    return (
      <Overlay isVisible={isVisible}>
        <Container isVisible spinnerColor={spinnerColor} spinnerBorderWidth={spinnerBorderWidth} {...divProps}>
          <div />
          <div />
          <div />
          <div />
        </Container>
      </Overlay>
    )
  }

  return (
    <Container isVisible={isVisible} spinnerColor={spinnerColor} spinnerBorderWidth={spinnerBorderWidth} {...divProps}>
      <div />
      <div />
      <div />
      <div />
    </Container>
  )
}
