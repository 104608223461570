const LoopIcon = (props) => {
    return (
        <svg {...props} viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.40671 0.110492C4.22275 0.110492 0 4.33324 0 9.5172C0 14.7012 4.22275 18.9239 9.40671 18.9239C11.661 18.9239 13.7313 18.1233 15.3539 16.7946L22.3815 23.8222V23.822C22.5566 24.0041 22.7977 24.108 23.0503 24.1103C23.3028 24.1128 23.5458 24.0135 23.7245 23.8348C23.903 23.6561 24.0023 23.4132 24 23.1606C23.9974 22.908 23.8935 22.6672 23.7117 22.4919L16.684 15.4642C18.013 13.8416 18.8133 11.7713 18.8133 9.51706C18.8133 4.3331 14.5906 0.110352 9.40664 0.110352L9.40671 0.110492ZM9.40671 1.99183C13.574 1.99183 16.9321 5.34992 16.9321 9.5172C16.9321 13.6845 13.574 17.0426 9.40671 17.0426C5.23943 17.0426 1.88134 13.6845 1.88134 9.5172C1.88134 5.34992 5.23943 1.99183 9.40671 1.99183Z" fill="#219653"/>
            <path d="M9.39084 5.74131C9.14181 5.74509 8.90434 5.84777 8.7307 6.02645C8.55706 6.20535 8.46152 6.44556 8.46488 6.69481V8.57615H6.58354C6.33178 8.57258 6.08906 8.67022 5.90975 8.84702C5.73044 9.02382 5.62944 9.26507 5.62944 9.51683C5.62944 9.76859 5.73044 10.0098 5.90975 10.1866C6.08906 10.3634 6.33179 10.4611 6.58354 10.4575H8.46488V12.3388C8.46131 12.5906 8.55895 12.8333 8.73575 13.0126C8.91254 13.192 9.1538 13.2929 9.40556 13.2929C9.65752 13.2929 9.89878 13.192 10.0756 13.0126C10.2524 12.8333 10.3498 12.5906 10.3462 12.3388V10.4575H12.2276C12.4793 10.4611 12.7221 10.3634 12.9014 10.1866C13.0807 10.0098 13.1817 9.76859 13.1817 9.51683C13.1817 9.26507 13.0807 9.02382 12.9014 8.84702C12.7221 8.67022 12.4793 8.57259 12.2276 8.57615H10.3462V6.69481C10.3498 6.44054 10.2503 6.19571 10.0701 6.01597C9.89018 5.83645 9.64513 5.73732 9.39084 5.74131Z" fill="#219653"/>
        </svg>

    )
}

export default LoopIcon;