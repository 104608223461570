import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  //   AnalyticSection,
  AnswerSection,
  //   BenefitsCardSection,
  ChoseForSection,
  ContactSection,
  // DoubtSection,
  HeaderSection,
  RateSection,
} from "../components";
import ConfirmSubscribeModal from "../components/ConfirmSubscribeModal/ConfirmSubscribeModal";
import ConsultationSection from "../components/ConsultationSection/ConsultationSection";
import NewDoubtSection from "../components/NewDoubtSection/NewDoubtSection";
import NewToolsSection from "../components/NewToolsSection/NewToolsSection";
// import {InnerAnalyticSection} from "../components/InnerAnalyticSection/InnerAnalyticSection";

const HomePage = ({ rateRef }) => {
  const location = useLocation();
  const [modalWithScreen, setModalWithScreen] = useState(
    location.hash.includes("#opt-in")
  );

  const closeModalWithScreen = () => {
    history.pushState("", document.title, window.location.pathname);
    setModalWithScreen(null);
  };

  useEffect(() => {
    const hash = location.hash;
    if (hash) {
      const element = document.querySelector(hash);
      if (element) {
        const elementTop = element.getBoundingClientRect().top;
        const offset = 50;

        // Scroll to the position with offset
        window.scrollTo({
          top: window.scrollY + elementTop - offset, // Use window.scrollY instead of pageYOffset
          behavior: "smooth", // Smooth scroll
        });
      }
    }
  }, [location]);

  return (
    <>
      <HeaderSection />
      {/*<AnalyticSection/>*/}
      {/*<WeCheckBenefitsSection/>*/}
      {/*<BenefitsCardSection/>*/}
      {/*<InnerAnalyticSection />*/}
      <NewToolsSection />

      <ContactSection
        text={
          <>
            <p>Читайте наш блог о маркетплейсах!</p>
            <p>Лайфаки, как зарабатывать больше.</p>
            <p>Раз в неделю, без спама</p>
          </>
        }
        className="!mt-0 lg:!pt-[84px] !bg-[#F4F5F6] lg:bg-white"
        innerClass="!bg-[#F4F5F6] lg:!bg-white"
      />
      <ConsultationSection />
      {/*<DoubtSection/>*/}
      <NewDoubtSection />
      <RateSection rateRef={rateRef} />
      <ChoseForSection />
      <AnswerSection />
      <ContactSection />
      {modalWithScreen && (
        <ConfirmSubscribeModal closeModal={closeModalWithScreen} />
      )}
    </>
  );
};

export default HomePage;
