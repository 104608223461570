import React, { useState } from "react";
import { PrimaryButton } from "../../elements";
import {
  StyledCardContainer,
  StyledIconContainer,
  StyledTitleContainer,
  StyledTextContainer,
  StyledSubTextContainer,
  StyledButtonContainer, StyledScreenshotLink,
} from "./AnalyticCardStyled";
import FireIcon from "../../assets/img/fire3.png";
import LinkIcon from "../../assets/svg/link.svg";
import { ImgPopup } from "../../elements";
import ym from "react-yandex-metrika";
import gtag from "ga-gtag";

export const AnalyticCardComponent = ({
  Icon,
  title,
  text,
  subText,
  button,
  height,
  imgPopup,
  popup,
  id,
  name,
  isInner,
}) => {
  const [open, setOpen] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const closeModal = () => setOpen(false);

  const analyticFunc = (name) => {
    if (name === "goods") {
      gtag("event", "click_cards", {
        cards: "advantage_goods",
      });
      ym("reachGoal", "click_cards_goods");
    }
    if (name === "sales") {
      gtag("event", "click_cards", {
        cards: "advantage_sales",
      });
      ym("reachGoal", "click_cards_sales");
    }
    if (name === "category") {
      gtag("event", "click_cards", {
        cards: "advantage_category",
      });
      ym("reachGoal", "click_cards_category");
    }
    if (name === "supply") {
      gtag("event", "click_cards", {
        cards: "advantage_supply",
      });
      ym("reachGoal", "click_cards_supply");
    }
    if (name === "brands") {
      gtag("event", "click_cards", {
        cards: "advantage_brands",
      });
      ym("reachGoal", "click_cards_brands");
    }
    if (name === "size") {
      gtag("event", "click_cards", {
        cards: "advantage_size",
      });
      ym("reachGoal", "click_cards_size");
    }
  };

  const handleClick = (e, name) => {
    const nodeName = e.target.nodeName;
    if (nodeName !== "BUTTON" && nodeName !== "IMG" && popup) {
      analyticFunc(name);
      setOpen(true);
    }
  };
  const [openDemo, setOpenDemo] = useState(false);

  const closeModalDemo = () => {
    setOpenDemo(false);
    window.location.hash === "";
  };

  return (
    <React.Fragment>
      <ImgPopup
        img={imgPopup ? imgPopup : ""}
        closeModal={() => closeModal()}
        open={open}
        title={title}
      />

      <StyledCardContainer
        id={id}
        popup={popup}
        height={height}
        onClick={(e) => handleClick(e, name)}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        isInner={isInner}
      >
        {isHovered && popup &&
            <StyledScreenshotLink>
              <span style={{ display: 'block', marginRight: 4 }}>Скриншот</span>
              <img src={LinkIcon} />
            </StyledScreenshotLink>
        }
        <StyledIconContainer>{Icon && <Icon />}</StyledIconContainer>
        <StyledTitleContainer popup={popup} isInner={isInner}>
          <p>{title}</p>
        </StyledTitleContainer>
        {text && (
          <StyledTextContainer>
            <p>{text}</p>
          </StyledTextContainer>
        )}
        {subText && (
          <StyledSubTextContainer>
            <p>{subText}</p>
          </StyledSubTextContainer>
        )}
        {button && (
          <StyledButtonContainer>
            <PrimaryButton
              onClick={(e) => {
                setOpenDemo(true);
                e.stopPropagation();
              }}
              open={openDemo}
              closeModal={() => closeModalDemo()}
              icon={FireIcon}
              tag={() => {
                gtag("event", "form_send", {
                  form: "demo_form",
                });
                gtag("event", "form_send", {
                  form: "demo_form_all",
                });
                gtag("event", "form_send", {
                  form: "all_leads",
                });
              }}
              tag2={() => {
                ym("reachGoal", "cards_demo_form");
                ym("reachGoal", "demo_form_all");
                ym("reachGoal", "all_leads");
              }}
            >
              {button}
            </PrimaryButton>
          </StyledButtonContainer>
        )}
      </StyledCardContainer>
    </React.Fragment>
  );
};
