import {StyledChatsMenu, StyledMenuChat, StyledMenuChatText} from "./PhoneChatsStyle";
import { ReactComponent as Phone } from "../../assets/svg/phone.svg";
import { ChatLinks, PHONE_NUMBER } from "./constants";
import React from "react";

const PhoneChatsMenu = () => {
  return (
    <StyledChatsMenu>
    {
      ChatLinks.map(({ Icon, link, id: chatId, tooltip, onClick }) =>
        <StyledMenuChat target="_blank" href={link} id={chatId} key={chatId}
          onClick={(e) => {
            e.stopPropagation();
            if (onClick) {
              e.preventDefault();
              onClick();
            }
          }}
        >
          <StyledMenuChatText>
            <Icon />
            <span style={{ marginLeft: 8 }}>{ tooltip }</span>
          </StyledMenuChatText>
        </StyledMenuChat>
      )
    }
    <StyledMenuChat style={{ padding: '10px 0'}} href="tel: +74951615155">
      <StyledMenuChatText>
        <Phone height={32} style={{ margin: '0 8px'}}/>
        <span style={{ marginLeft: 8 }}>{PHONE_NUMBER}</span>
      </StyledMenuChatText>
    </StyledMenuChat>
    </StyledChatsMenu>
  )
}

export { PhoneChatsMenu };