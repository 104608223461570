import Product from "../../assets/img/popup-img/product.png";
import Seller from "../../assets/img/popup-img/seller.png";
import Category from "../../assets/img/popup-img/category.png";
import Store from "../../assets/img/popup-img/store.png";
import SizeColor from "../../assets/img/popup-img/size-color.png";
import PopupBen2 from "../../assets/img/popup-img/benefit2.png";
import PriceSegments from "../../assets/img/popup-img/PriceSegments.jpeg";
import Dynamic from "../../assets/img/popup-img/Dynamic.jpeg";
import New from "../../assets/img/popup-img/New.jpeg";
import Potential from "../../assets/img/popup-img/Potential.jpeg";
import Ext_01 from "../../assets/img/popup-img/Ext_01.png";
import Ext_02 from "../../assets/img/popup-img/Ext_02.png";
import Ext_04 from "../../assets/img/popup-img/Ext_04.png";
import Ext_06 from "../../assets/img/popup-img/Ext_06.png";
import Similar from "../../assets/img/popup-img/Similar.jpeg";
import Changes from "../../assets/img/popup-img/Changes.jpeg";
import Quality from "../../assets/img/popup-img/Quality.jpeg";
import Favorites from "../../assets/img/popup-img/Favorites.jpeg";
import Brand from "../../assets/img/popup-img/brand.png";
import EveryDay from "../../assets/img/popup-img/EveryDay.jpeg";
import InnerProducts from "../../assets/img/popup-img/InnerProducts.jpeg";
import InnerRevert from "../../assets/img/popup-img/InnerRevert.jpeg";
import InnerFinantial_01 from "../../assets/img/popup-img/InnerFinantial_01.jpeg";
import InnerFinantial_02 from "../../assets/img/popup-img/InnerFinantial_02.jpeg";
import InnerWarehouses from "../../assets/img/popup-img/InnerWarehouses.jpeg";
import InnerSizes from "../../assets/img/popup-img/InnerSizes.jpeg";
import InnerPlaning from "../../assets/img/popup-img/InnerPlaning.jpeg";
import InnerPotintial from "../../assets/img/popup-img/InnerPotintial.jpeg";
import InnerABS from "../../assets/img/popup-img/InnerABS.jpeg";
import InnerPrices from "../../assets/img/popup-img/InnerPrices.jpeg";
import InnerWarning from "../../assets/img/popup-img/InnerWarning.jpeg";
import InnerExpress from "../../assets/img/popup-img/InnerExpress.jpeg";
import InnerWeeks from "../../assets/img/popup-img/InnerWeeks.jpeg";
import SeoWords01 from "../../assets/img/popup-img/Seo_words_01.jpeg";
import SeoWords02 from "../../assets/img/popup-img/Seo_words_02.jpeg";
import SeoWords03 from "../../assets/img/popup-img/Seo_words_03.jpeg";
import SeoHints01 from "../../assets/img/popup-img/SeoHints_01.jpeg";
import SeoHints02 from "../../assets/img/popup-img/SeoHints_02.jpeg";
import SeoCompare01 from "../../assets/img/popup-img/SeoCompare_01.jpeg";
import SeoCompare02 from "../../assets/img/popup-img/SeoCompare_02.jpeg";
import SeoTracker from "../../assets/img/popup-img/SeoTracker.jpeg";
import SeoMonitoring01 from "../../assets/img/popup-img/SeoMonitoring_01.jpeg";
import SeoMonitoring02 from "../../assets/img/popup-img/SeoMonitoring_02.jpeg";
import SeoPositions from "../../assets/img/popup-img/SeoPositions.jpeg";
import SeoSmartWords01 from "../../assets/img/popup-img/SeoSmartWords_01.jpeg";
import SeoSmartWords02 from "../../assets/img/popup-img/SeoSmartWords_02.jpeg";
import SmartDescription from "../../assets/img/popup-img/SmartDescription.jpeg";
import Advertising01 from "../../assets/img/popup-img/Advertising_01.jpeg";
import Advertising02 from "../../assets/img/popup-img/Advertising_02.jpeg";

export const TOOLS = [
    {
        id: 'analytic',
        label: 'Аналитика конкурентов',
        subTools: [
            {
                number: '01',
                id: 'products',
                label: 'Аналитика по Товарам',
                title: 'Покажем топы товаров для продажи',
                subTitle: '32 параметра для анализа по каждому товару. Находите бестселлеры, анализируйте факторы успеха и зарабатывайте больше',
                images: [Product],
            },
            {
                number: '02',
                id: 'sellers',
                label: 'Аналитика по Продавцам',
                title: 'Покажем стратегии продавцов',
                subTitle: 'Изучайте, как конкуренты работают с ассортиментом и поставками. Находите лучших продавцов в каждой категории, изучайте их стратегии и опережайте',
                images: [Seller],
            },
            {
                number: '03',
                id: 'categories',
                label: 'Аналитика по Категориям',
                title: 'Покажем лучшие категории для торговли',
                subTitle: 'WeCheck покажет объемы продаж по категориям. Изучите потенциал, конкурентность и перспективы в каждой категории маркетплейса',
                images: [Category],
            },
            {
                number: '04',
                id: 'warehouses',
                label: 'Аналитика по Складам и Регионам',
                title: 'Покажем стратегии поставок по регионам',
                subTitle: 'Изучите статистику по складам по каждому товару, продавцу или бренду. Узнайте, на какие склады приходится максимум продаж в нише, и правильно спланируйте логистику',
                images: [Store],
            },
            {
                number: '05',
                id: 'colors-sizes',
                label: 'Аналитика по Цветам и Размерам',
                title: 'Покажем лучшие цвета и популярные размеры',
                subTitle: 'WeCheck покажет, какие цвета и размеры продаются, а какие лежат на складе. Экономьте на закупках с аналитикой WeCheck по цветам и размерам',
                images: [SizeColor],
            },
            {
                number: '06',
                id: 'positions',
                label: 'Трекер Позиций',
                title: 'Покажем позиции карточек в каждой категории',
                subTitle: 'Отслеживайте динамику позиций своих и товаров конкурентов',
                images: [PopupBen2],
            },
            {
                number: '07',
                id: 'segments',
                label: 'Ценовые Сегменты',
                title: 'Покажем в каком ценовом сегменте лучше продавать ',
                subTitle: 'Изучайте какой ценовой сегмент в каждой категории имеет лучшие перспективы для продаж',
                images: [PriceSegments],
            },
            {
                number: '08',
                id: 'dynamic',
                label: 'Динамика и Тренды',
                title: 'Отобразим ниши, где проще стать лидерами',
                subTitle: 'Покажем какие категории, продавцы и бренды набирают обороты, как меняется конкурентность, средний чек и где зарабатывать проще',
                images: [Dynamic],
            },
            {
                number: '09',
                id: 'isNew',
                label: 'Аналитика Новинок',
                title: 'Покажем, какие Новинки сейчас на пике спроса',
                subTitle: 'Покажем продажи новинок у продавцов в каждой категории. Продавайте трендовые товары на пике спроса',
                images: [New],
            },
            {
                number: '10',
                id: 'lost-orders',
                label: 'Потенциал и Упущенные заказы',
                title: 'Рассчитаем потенциал продаж для каждого товара, продавца, бренда или категории ',
                subTitle: 'Рассчитаем сколько заказов недополучил каждый товар, продавец и категория. Оценим потенциал рынка и эффективность работы конкурентов',
                images: [Potential],
            },
            {
                number: '11',
                id: 'extension',
                label: 'Расширение для wildberries',
                title: 'Покажем информацию о продажах на сайте Wildberries',
                subTitle: 'Покажем подробную статистику за 30 дней: заказы, упущенную выручку, потенциал, остаток товара. Аналитика доступна для каждого товара, продавца и бренда.',
                images: [Ext_01, Ext_02, Ext_04, Ext_06],
            },
            {
                number: '12',
                id: 'similar',
                label: 'Похожие Товары',
                title: 'Покажем как продаются похожие товары у любого из артикулов',
                subTitle: 'Оцените объемы продаж товаров в сравнении с похожими, чтобы понять эффективность',
                images: [Similar],
            },
            {
                number: '13',
                id: 'changes',
                label: 'Изменения Товаров',
                title: 'Покажем важные изменения у каждого товара',
                subTitle: 'Отобразим когда у товара менялась цена, фотографии и описание товара, и как изменения повлияли на продажи',
                images: [Changes],
            },
            {
                number: '14',
                id: 'content-quality',
                label: 'Качество Контента ',
                title: 'Оценим качество карточек у конкурентов ',
                subTitle: 'Изучите насколько качественно заполнены карточки у конкурентов и как это повлияло на продажи',
                images: [Quality],
            },
            {
                number: '15',
                id: 'brands',
                label: 'Аналитика по Брендам',
                title: 'Аналитика по Брендам',
                subTitle: 'Анализируйте, с какими марками работают лидеры',
                images: [Brand],
            },
            {
                number: '16',
                id: 'favorites',
                label: 'Избранное',
                title: 'Быстрый доступ к нужной информации',
                subTitle: 'Добавляйте в избранное важные товары, категории, продавцов и бренды, чтобы быстро получать нужную информацию',
                images: [Favorites],
            },
        ]
    },
    {
        id: 'inner',
        label: 'Управление продажами',
        subTools: [
            {
                number: '01',
                id: 'inDays',
                label: 'Ежедневная динамика',
                title: 'Следите за динамикой продаж в режиме реального времени',
                subTitle: 'Покажем как меняются результаты день за днем ',
                images: [EveryDay]
            },
            {
                number: '02',
                id: 'Аналитика по товарам',
                label: 'Аналитика по товарам',
                title: 'Покажем детальную аналитику по каждому товару ',
                subTitle: '51 параметр для анализа каждого товара поможет выделить самые прибыльные позиции и убрать из ассортимента товары, которые приносят убытки',
                images: [InnerProducts]
            },
            {
                number: '03',
                id: 'Аналитика заказов возвратов и продаж',
                label: 'Аналитика заказов возвратов и продаж',
                title: 'Отобразим Заказы и динамику их статусов ',
                subTitle: 'Покажем заказы, как меняются статусы и с каких регионов приходят клиенты ',
                images: [InnerRevert],
            },
            {
                number: '04',
                id: 'Аналитика финансовых показателей',
                label: 'Аналитика финансовых показателей',
                title: 'Рассчитаем Прибыль, Маржинальность и Рентабельность',
                subTitle: 'Максимизируйте прибыль: следите за маржинальностью и рентабельность товаров',
                images: [InnerFinantial_01, InnerFinantial_02],
            },
            {
                number: '05',
                id: 'Аналитика по складам ',
                label: 'Аналитика по складам ',
                title: 'Рассчитаем упущенную выгоду из-за неправильных поставок по складам',
                subTitle: 'Отслеживайте заказы и остатки, уменьшайте потери и оптимизируйте логистику ',
                images: [InnerWarehouses],
            },
            {
                number: '06',
                id: 'Аналитика по размерам',
                label: 'Аналитика по размерам',
                title: 'Покажем популярные размеры, чтобы вы экономили на закупках',
                subTitle: 'Управляйте размерами товаров, уменьшайте упущенную прибыль и повышайте эффективность бизнеса',
                images: [InnerSizes],
            },
            {
                number: '07',
                id: 'Планирование поставок',
                label: 'Планирование поставок',
                title: 'Оптимизируйте запасы на складах и избегайте потерь с нашим сервисом расчета поставок',
                subTitle: 'Рассчитываем идеальный баланс между запасами и оборачиваемостью. Покажем сколько товаров нужно поставить на каждый склад. Экономьте деньги и увеличивайте прибыль',
                images: [InnerPlaning],
            },
            {
                number: '08',
                id: 'Потенциал и Упущенные заказы',
                label: 'Потенциал и Упущенные заказы',
                title: 'Раскройте потенциал своих товаров',
                subTitle: 'Покажем, сколько вы можете заработать. Рассчитаем потенциал продаж и Упущенную выгоду для каждого товара.',
                images: [InnerPotintial],
            },
            {
                number: '09',
                id: 'ABC Анализ',
                label: 'ABC Анализ',
                title: 'Распределим товары по степени их значимости для бизнеса',
                subTitle: 'Узнайте, как распределить ресурсы между товарами эффективнее и максимизировать прибыль с помощью ABC анализа ',
                images: [InnerABS],
            },
            {
                number: '10',
                id: 'Трекер позиций',
                label: 'Трекер позиций',
                title: 'Узнайте позиции товаров по поисковым запросам и в категориях',
                subTitle: 'Отслеживайте, на каких позициях находится товар по поисковым запросам и повышайте его видимость для клиентов',
                images: [PopupBen2],
            },
            {
                number: '11',
                id: 'Похожие товары',
                label: 'Похожие товары',
                title: 'Сравните продажи товара с аналогами',
                subTitle: 'Сравните продажи ваших товаров с товарами конкурентов, чтобы оценить эффективность',
                images: [Similar],
            },
            {
                number: '12',
                id: 'Управление ценами',
                label: 'Управление ценами',
                title: 'Настраивайте цены в удобном интерфейсе',
                subTitle: 'Управляйте ценами и скидками, без эксель таблиц. Назначайте цену сейчас или с любого времени в будущем',
                images: [InnerPrices],
            },
            {
                number: '13',
                id: 'Товары требующие внимания',
                label: 'Товары требующие внимания',
                title: 'Настраивайте мониторинг, по важным показателям ',
                subTitle: 'Контролируйте товары, которые выходят за границы приемлемых значений по Маржинальности, Упущенной Выручке, Проценту Выкупа, Оборачиваемости и другим важным показателям ',
                images: [InnerWarning]
            },
            {
                number: '14',
                id: 'Панель экспресс анализа бизнеса',
                label: 'Панель экспресс анализа бизнеса',
                title: 'Наглядно отображаем результаты продаж, финансовой эффективности, возможности, и остатки на складах',
                subTitle: 'Используйте удобный дашборд для анализа ключевых показателей бизнеса',
                images: [InnerExpress]
            },
            {
                number: '15',
                id: 'Еженедельные отчеты ',
                label: 'Еженедельные отчеты ',
                title: 'Изучайте еженедельный отчет с настраиваемыми фильтрами',
                subTitle: 'Применяйте фильтры по брендам, категориям и товарам при изучении итогового отчета от Wildberries',
                images: [InnerWeeks]
            },
        ],
    },
    {
        id: 'seo',
        label: 'SEO-продвижение',
        subTools: [
            {
                number: '01',
                id: 'Подбор ключевых слов',
                label: 'Подбор ключевых слов',
                title: 'Подберем ключевые слова собрав информацию из топа ',
                subTitle: 'Укажите артикулы или поисковые фразы, а WeCheck покажет слова, которые необходимо использовать для увеличения видимости товаров',
                images: [SeoWords01, SeoWords02, SeoWords03]
            },
            {
                number: '02',
                id: 'Подбор слов по подсказкам',
                label: 'Подбор слов по подсказкам',
                title: 'Проверим подсказки в поисковой строке по вашему запросу',
                subTitle: 'Используйте подсказки для описания товаров. Подсказки отражают, что ищут пользователи сегодня',
                images: [SeoHints01, SeoHints02]
            },
            {
                number: '03',
                id: 'Сравнение товаров',
                label: 'Сравнение товаров',
                title: 'Сравним видимость товаров',
                subTitle: 'Проверим по каким фразам находят товар, сравним с конкурентами и подскажем какие слова нужно добавить в описание, чтобы подняться в топ ',
                images: [SeoCompare01, SeoCompare02]
            },
            {
                number: '04',
                id: 'Трекер позиций',
                label: 'Трекер позиций',
                title: 'Покажем изменения позиций у товаров по поисковым фразам',
                subTitle: 'Следите за результатами SEO оптимизации товаров',
                images: [SeoTracker],
            },
            {
                number: '05',
                id: 'Мониторинг товаров',
                label: 'Мониторинг товаров',
                title: 'Отслеживайте динамику товаров по поисковым фразам',
                subTitle: 'Мониторинг товаров по выбранным поисковым фразам, для контроля SEO продвижения',
                images: [SeoMonitoring01, SeoMonitoring02]
            },
            {
                number: '06',
                id: 'Позиции товаров',
                label: 'Позиции товаров',
                title: 'Покажем какие товары в топе по поисковой фразе ',
                subTitle: 'Покажем топ 50 товаров по поисковой фразе, а также динамику изменения позиций по этим товарам ',
                images: [SeoPositions],
            },
            {
                number: '07',
                id: 'Умный подбор слов ',
                label: 'Умный подбор слов ',
                title: 'Подберем слова для описания товара',
                subTitle: 'Укажите артикул. WeCheck проанализирует категорию товара, изучит конкурентов и покажет слова и фразы, которые повысят видимость и увеличат продажи товара',
                images: [SeoSmartWords01, SeoSmartWords02],
            },
            {
                number: '08',
                id: 'Умное описание товаров',
                label: 'Умное описание товаров',
                title: 'Разработаем описание товара с помощью нейросети',
                subTitle: 'Укажите артикул. WeCheck проанализирует товары, применит технологии искусственного интеллекта и создаст описание товара. Это описание поможет артикулу подняться в топ выдачи и увеличить продажи',
                images: [SmartDescription],
            },

        ],
    },
    {
        id: 'advertising',
        label: 'Эффективная реклама',
        subTools: [
            {
                number: '01',
                id: 'Проверка рекламных ставок ',
                label: 'Проверка рекламных ставок ',
                title: 'Покажем реальные ставки по поисковому запросу',
                subTitle: 'WeCheck покажет точные ставки по нужному вам запросу. Ориентируйтесь на реальные ставки и экономьте бюджет',
                images: [Advertising01, Advertising02]
            },
        ],
    }
]