import React from "react";
import { HeadSectionComponent } from "../HeadSection/HeadSectionComponent";
import { Container } from "../../elements";
// import { StyledHeadSection } from "./HeaderSectionStyle";

export const HeaderSection = () => {
  return (
    //<StyledHeadSection>
      <div className="relative overflow-hidden">
      <Container head={true}>
        <HeadSectionComponent />
      </Container>
          <div className="absolute"
               style={{ top: 802, left: -257, background: '#279653', filter: 'blur(250px)', width: 748, height: 801}}/>
          <div className="absolute"
               style={{ top:
                       846, left: 548, background: '#303F59', filter: 'blur(250px)', width:
                       454, height: 487}}/>
          <div className="absolute"
               style={{ top: 792, left: 1010, background: '#279653', filter: 'blur(250px)', width: 687, height: 736}}/>
      </div>
    // </StyledHeadSection>
  );
};
