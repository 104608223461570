import styled from "styled-components";

export const StyledButton = styled.button`
  border: none;
  background-color: #f0fcf5;
  padding: 12px 24px;
  border-radius: 100px;
  color: #00745F;
  font-family: "Sofia Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 140%;
  cursor: pointer;
  transition: 0.3s;
  &:hover {
    background: #f0fcf5;
    color: #00745F;
  }
  @media (max-width: 480px) {
    font-size: 16px;
    padding: 8px 16px;
  }
`;
