import styled from "styled-components";

export const StyledHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 84px 0 3.8rem 0;

  @media (max-width: 870px) {
    justify-content: center;
    flex-flow: column nowrap;
    gap: 1rem;
    border-bottom: 1px solid #e7ebeb;
    padding: 2rem 0 0;
    margin-bottom: 16px;
  }
`;

export const StyledSection = styled.section`
  background: #f4f5f6;
  // padding-top: 100px;
  // border-radius: 80px;
  z-index: 3;

  @media (max-width: 870px) {
    border-radius: 0;
    padding-top: 0;
  }
`;

export const StyledTitle = styled.h4`
  font-family: "Sofia Pro";
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 48px;
  letter-spacing: -0.3px;
  color: #213a2b;

  @media (max-width: 870px) {
    font-size: 32px;
    line-height: 37px;
    text-align: center;
  }

  @media (max-width: 560px) {
    font-size: 24px;
  }
`;

export const StyledButtonGroup = styled.div`
  display: flex;
  gap: 0.3rem;
  
  button {
    height: fit-content;
    padding: 0.5rem 1rem;
    font-size: 1rem;
  }

  @media (max-width: 870px) {
    button {
      position: relative;
      padding: 0.5rem 1rem;
    }
  }

  @media (max-width: 476px) {
    gap: 0.5rem;
    width: 100%;
    padding:0 16px;
    button {
      padding: 0.5rem;
    }
  }
`;

export const StyledRateBlock = styled.div`
  display: flex;
  padding-bottom: 118px;
  width: 100%;
  div {
    width: 100%;
  }

  @media (max-width: 1080px) {
    //flex-direction: column;
    //align-items: center;
    //padding-bottom: 0;
  }
`;
