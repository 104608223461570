import { createGlobalStyle } from "styled-components";
import GerberaRegular from "./assets/fonts/Gerbera.woff";
import GerberaBold from "./assets/fonts/Gerbera-Bold.woff";
import SofiaRegular from "./assets/fonts/SofiaPro-Regular.otf";
import SpfiaBold from "./assets/fonts/SofiaPro-Bold.otf";

export const GlobalStyle = createGlobalStyle`
* {
  margin: 0;
  padding: 0;
}

@font-face {
  font-family: "Gerbera";
  src: local("Gerbera"), url(${GerberaRegular}) format("woff");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Gerbera";
  src: local("Gerbera"), url(${GerberaBold}) format("woff");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Sofia Pro";
  font-style: normal;
  font-weight: 400;
  src: local("Sofia Pro"), url(${SofiaRegular}) format("opentype");
}
@font-face {
  font-family: "Sofia Pro";
  font-style: normal;
  font-weight: 700;
  src: local("Sofia Pro"), url(${SpfiaBold}) format("opentype");
}

body {
  margin: 0;
  font-family: "Sofia Pro", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

section {
  position: relative;
}

.app-menu {
  color: #313d53;
  height: 100vh;
  width: 90%;
  background-color: #fff;
  z-index: 200;

  transform: translateX(100%);
  transition: 0.3s;

  position: fixed;
  right: 0;
  top: 0;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.menu-open {
  transform: translateX(0%);
}

.carousel .control-dots {
  color: #289e5b;
}

.carousel .control-dots .dot {
  width: 4px !important;
  height: 4px !important;
  background: #849e8f !important;
}
.carousel .control-dots .dot.selected {
  width: 20px !important;
  border-radius: 4px !important;
  background: #289e5b !important;
  box-shadow: none !important;
}
.carousel .slide {
  text-align: left !important;
}

.carousel.carousel-slider {
  padding-bottom: 18px;
}

main {
  flex-grow: 1;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
`;
