import styled from 'styled-components';
import { InputStyled } from "../Input/InputStyle";
import { CheckboxContainer } from "../Checkbox/CheckboxStyle";

export const Title = styled.h1`
  font-weight: 700;
  font-size: 44px;
  line-height: 100%;
  letter-spacing: -0.3px;
  
  margin-bottom: 30px;

  color: #00745F;

  @media (min-width: 768px) {
    font-weight: 700;
    font-size: 103px;
    
    margin-bottom: 50px;
  }
`;

export const TitleText = styled.h2`
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  
  margin-bottom: 20px;

  @media (min-width: 768px) {
    font-size: 32px;
    line-height: 40px;
    
    margin-bottom: auto;
  }
`;

export const Text = styled.p`
  font-weight: 400;
  font-size: 12px;
  line-height: 140%;
  
  a {
    font-weight: 600;
    color: #00745F;
  }

  @media (min-width: 768px) {
    font-size: 20px;
    line-height: 150%; 
  }
`;

export const ErrorText = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #FF5522;
  margin-left: 40px;
`;

export const StockFormButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  
  margin-bottom: auto;

  background: #00745F;
  
  font-weight: 700;
  font-size: 16px;
  line-height: 120%;
  color: #FFFFFF;
  
  border: none;
  border-radius: 0 0 20px 20px;
  
  width: calc(100% + 40px);
  height: 60px;
  margin: 0 -20px;
  
  margin-top: auto;

  @media (min-width: 1024px) {
    margin: auto -128px 0;
    
    width: calc(100% + 256px);
    height: 100px;

    font-size: 27px;
    line-height: 40px;
  }
`;

export const StockForm = styled.div`
  display: flex;
  flex-direction: column;
  
  padding: 20px 20px 0;
  
  background: #FFFFFF;
  
  margin-top: 40px;

  border-radius: 40px 40px 20px 20px;

  box-shadow: 0px 4px 110px rgba(31, 155, 81, 0.25);
  
  flex-grow: 1;
  
  height: 100%;
  
  box-sizing: border-box;
  
  min-height: 550px;
  
  ${ErrorText} + ${StockFormButton} {
    margin-top: auto;
  }
  
  ${TitleText} {
    margin-bottom: 20px;
  }
  
  ${Text} {
    margin-bottom: 30px;
    
    @media (min-width: 768px) {
      margin-bottom: 64px;
    }
  }
  
  ${InputStyled} {
    box-sizing: border-box;
    display: block;
    margin-bottom: 20px;
    width: 100%;
  }
  
  overflow: hidden;
  
  ${CheckboxContainer} {
    margin-bottom: ${(props) => props.error ? '12px' : '20px'};
    
    @media (min-width: 768px) {
      margin-bottom: ${(props) => props.error ? '16px' : '64px'};
    }
  }

  @media (min-width: 1024px) {
    margin-top: 0;
    
    padding: 64px 64px 0;
    
    flex-basis: 477px;
    
    min-width: 477px;

    min-height: 700px;
  }
`;

export const StockContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 40px 0;
  width: 100%;
  
  @media (min-width: 1024px) {
    flex-direction: row;
    flex: 1 1;
    padding: 120px 0 150px 0;
  }
`;

export const LeftSideContent = styled.div`
  @media (min-width: 1024px) {
    display: flex;
    flex-direction: column;

    margin-right: 165px;
    
    ${TitleText} {
      margin-bottom: 110px;
    }
    
    ${Text} {
      margin-top: auto;
    }
  }
`;

export const RightSideContent = styled.div`
  position: relative;

  @media (min-width: 1024px) {
    margin-left: auto;
    
    flex-basis: 477px;
  }
`;

export const FirstRound = styled.div`
  position: absolute;
  
  
  @media (max-width: 1023px) {
    bottom: 0;
    left: 0;
    width: 100%;
    height: 20%;
    filter: blur(150px);
    background: linear-gradient(to right, #0094FF 0%, #FF00D7 100%);
  }
  
  @media (min-width: 1024px) {
    height: 40%;
    width: 50%;
    right: 0;
    top: 45%;
    filter: blur(200px);
    background: linear-gradient(#FF00D7, #D0F53B);
  }
`;

export const SuccessImage = styled.img`
  height: 65px;
  width: 65px;
  margin-bottom: 16px;
  
  @media (min-width: 1024px) {
    height: 95px;
    width: 95px;
    margin-bottom: 42px;
  }
`;