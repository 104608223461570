import styled from 'styled-components';

export const StyledDesktop = styled.div`
  display: block;
  @media (max-width: 480px) {
    display: none;
  }
`;

export const StyledMobile = styled.div`
  display: none;
  @media (max-width: 480px) {
    display: block;
  }
`;
const AdvertisingBlock = ({ desktopContent, mobileContent }) => {
    return (
        <div>
            <StyledDesktop>
                {desktopContent}
            </StyledDesktop>
            <StyledMobile>
                {mobileContent}
            </StyledMobile>
        </div>
    )
}

export default AdvertisingBlock;